import { CEFRLevels } from '@astrid/components'
import { Button, Checkbox, FormControlLabel, Grid, MenuItem, Switch, TextField } from '@material-ui/core'
import { Formik, FormikHelpers } from 'formik'
import React, { useContext, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import useSWR from 'swr'
import * as Yup from 'yup'
import emptyCoverUrl from '../../assets/empty_448x448.png'
import { ROUTES } from '../../routes/routes'
import { printApiMessage } from '../../shared/api/apiMessages'
import { ApiReqState } from '../../shared/api/types'
import { AlertContext } from '../../shared/components/AlertContext/AlertContext'
import { ConfirmPopup } from '../../shared/components/ConfirmPopup/ConfirmPopup'
import ImageField, { CroppedResult } from '../../shared/components/ImageField/ImageField'
import MultiSelector from '../../shared/components/MultiSelector/MultiSelector'
import { jsonToFormData } from '../../shared/helpers/jsonToFormData'
import { ValidationRules } from '../../shared/helpers/ValidationRules'
import { deleteContentTags, updateContentTags } from '../../store/services/ContentTag/contentTagReducer'
import { ContentTagModel, ContentType, CONTENT_TAG_AGE_OPTIONS } from '../../store/services/ContentTag/types'
import StudySetApi from '../../store/services/StudySet/studySetApi'
import {
  createStudySet,
  deleteStudySet,
  publishStudySet,
  selectStudySetSingleReqState,
  unpublishStudySet,
  updateStudySet
} from '../../store/services/StudySet/studySetReducer'
import { StudySetModel } from '../../store/services/StudySet/types'
import TopicsApi from '../../store/services/Topics/topicsApi'
import { updateTopics } from '../../store/services/Topics/topicsReducer'
import { RootState } from '../../store/types'
import styles from './StudySetForm.module.scss'

const TITLE_MAX_CHARACTERS = 45

const validationSchema = Yup.object().shape({
  title: ValidationRules.required.max(TITLE_MAX_CHARACTERS),
  cefrLevel: ValidationRules.cefrLevel,
  objective: ValidationRules.required,
  framework: ValidationRules.optionalWithMaxLength,
  learnerObjective: ValidationRules.required
})

interface StudySetFormFields {
  title: string
  hideTitle: boolean
  objective: string
  topics: string[]
  framework: string
  learnerObjective: string
  attribution?: string
  cefrLevel: CEFRLevels
  image: CroppedResult | { url: string; blob?: null }
  imageOriginal: CroppedResult | { url: string; blob?: null }
  labelAdults: boolean
  labelKids: boolean
}

interface Props {
  studySet?: StudySetModel
  contentTags?: ContentTagModel
  topics: string[]
  allowEdit: boolean
}

const StudySetForm: React.FC<Props> = ({ studySet, contentTags, topics, allowEdit }) => {
  const history = useHistory()
  const { showAlert } = useContext(AlertContext)
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, AnyAction>>()
  const studySetReqState = useSelector<RootState, ApiReqState>((state) => selectStudySetSingleReqState(state))
  const [isDuplicating, setIsDuplicating] = useState(false)
  const isNew = !studySet
  const isPending = studySetReqState === ApiReqState.PENDING || isDuplicating
  const { data: allTopics } = useSWR('all-topics', () => TopicsApi.getAll().then((res) => res.data))

  const submitForm = async (
    {
      title,
      hideTitle,
      cefrLevel,
      objective,
      topics,
      attribution,
      framework,
      learnerObjective,
      image,
      imageOriginal,
      labelAdults,
      labelKids
    }: StudySetFormFields,
    { setSubmitting }: FormikHelpers<StudySetFormFields>
  ) => {
    const preparedData: any = {
      title,
      hideTitle,
      cefrLevel,
      objective,
      framework,
      learnerObjective,
      attribution
    }

    const tags = getTagList({
      [CONTENT_TAG_AGE_OPTIONS.ADULT]: labelAdults,
      [CONTENT_TAG_AGE_OPTIONS.KID]: labelKids
    })
    const allImageFieldsPopulated = (image.blob || image.url) && (imageOriginal.blob || imageOriginal.url)
    const fallbackImage = allImageFieldsPopulated ? null : await (await fetch(emptyCoverUrl)).blob()

    if (image.blob instanceof Blob) {
      preparedData.image = image.blob
    } else if (!image.url) {
      preparedData.image = fallbackImage
    }

    if (imageOriginal.blob instanceof Blob) {
      preparedData.imageOriginal = imageOriginal.blob
    } else if (!imageOriginal.url) {
      preparedData.imageOriginal = fallbackImage
    }

    try {
      if (isNew) {
        const { _id } = await dispatch(createStudySet(jsonToFormData(preparedData)))
        await dispatch(updateContentTags({ contentId: _id, contentType: ContentType.LessonTemplate, tags }))
        // wait for updateContentTags since content tags also include topics
        await dispatch(updateTopics({ contentId: _id, contentType: ContentType.LessonTemplate, topics }))
        history.push(`${ROUTES.STUDY_SETS}/${_id}`)
      } else {
        await Promise.all([
          dispatch(updateStudySet(studySet!._id, jsonToFormData(preparedData))),
          dispatch(
            updateContentTags({
              contentId: studySet!._id,
              contentType: ContentType.LessonTemplate,
              tags
            })
          )
        ])
        // wait for updateContentTags since content tags also include topics
        await dispatch(updateTopics({ contentId: studySet!._id, contentType: ContentType.LessonTemplate, topics }))
      }
      showAlert('Saved', 'success')
    } catch (error) {
      showAlert(printApiMessage(error))
    } finally {
      setSubmitting(false)
    }
  }

  const getTagList = (ageTags: {
    [CONTENT_TAG_AGE_OPTIONS.ADULT]: boolean
    [CONTENT_TAG_AGE_OPTIONS.KID]: boolean
  }) => {
    const tagObj: { [key: string]: boolean } = {}
    const currentTags = contentTags?.tags || []
    currentTags.forEach((tag) => {
      tagObj[tag] = true
    })
    tagObj[CONTENT_TAG_AGE_OPTIONS.ADULT] = ageTags[CONTENT_TAG_AGE_OPTIONS.ADULT]
    tagObj[CONTENT_TAG_AGE_OPTIONS.KID] = ageTags[CONTENT_TAG_AGE_OPTIONS.KID]

    return Object.keys(tagObj).filter((tag) => tagObj[tag])
  }

  const handleDelete = async () => {
    if (!studySet) {
      return
    }

    try {
      await Promise.all([
        dispatch(deleteStudySet(studySet!._id)),
        dispatch(deleteContentTags({ contentId: studySet!._id, contentType: ContentType.LessonTemplate }))
      ])
      history.push(ROUTES.STUDY_SETS)
    } catch (error) {
      showAlert(printApiMessage(error))
    }
  }

  const handlePublishing = async () => {
    if (!studySet) {
      return
    }

    try {
      if (studySet.active) {
        await dispatch(unpublishStudySet(studySet._id))
      } else {
        await dispatch(publishStudySet(studySet!._id))
      }
    } catch (error) {
      showAlert(printApiMessage(error))
    }
  }

  const duplicate = async () => {
    try {
      setIsDuplicating(true)
      const response = await StudySetApi.duplicate(studySet!._id)
      history.push(`${ROUTES.STUDY_SETS}/${response.data._id}`)
    } catch (error) {
      showAlert(printApiMessage(error))
    }
    setIsDuplicating(false)
  }

  return (
    <Formik<StudySetFormFields>
      enableReinitialize={true}
      initialValues={
        isNew
          ? {
              image: {
                url: ''
              },
              imageOriginal: {
                url: ''
              },
              title: '',
              hideTitle: false,
              objective: '',
              topics: [],
              framework: '',
              learnerObjective: '',
              attribution: '',
              cefrLevel: CEFRLevels.A11,
              labelAdults: true,
              labelKids: false
            }
          : {
              image: { url: studySet?.imageUrl || '', blob: null },
              imageOriginal: { url: studySet?.imageOriginalUrl || '', blob: null },
              title: studySet?.title || '',
              hideTitle: studySet?.hideTitle || false,
              topics: topics,
              objective: studySet?.objective || '',
              framework: studySet?.framework || '',
              attribution: studySet?.attribution || '',
              learnerObjective: studySet?.learnerObjective || '',
              cefrLevel: studySet?.cefrLevel || CEFRLevels.A11,
              labelAdults: contentTags?.tags.includes(CONTENT_TAG_AGE_OPTIONS.ADULT) ?? false,
              labelKids: contentTags?.tags.includes(CONTENT_TAG_AGE_OPTIONS.KID) ?? false
            }
      }
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values, helpers) => {
        submitForm(values, helpers)
      }}>
      {({
        handleChange,
        handleSubmit,
        values,
        errors,
        touched,
        setFieldTouched,
        setFieldValue,
        isSubmitting,
        dirty
      }) => {
        const isPublishDisabled = isSubmitting || isPending
        return (
          <>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Grid container item spacing={2}>
                  <Grid item xs={2}>
                    <TextField
                      name="cefrLevel"
                      label="Level"
                      required
                      disabled={!allowEdit}
                      id="cefrLevel"
                      variant="outlined"
                      select
                      margin="normal"
                      fullWidth
                      size="small"
                      onChange={handleChange('cefrLevel')}
                      value={values.cefrLevel}
                      onBlur={() => setFieldTouched('cefrLevel')}>
                      {Object.values(CEFRLevels).map((lvl) => (
                        <MenuItem value={`${lvl}`} key={lvl}>
                          {lvl}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs className={styles.titleContainer}>
                    <TextField
                      className={styles.titleField}
                      name="title"
                      label="Title"
                      required
                      inputProps={{ maxLength: TITLE_MAX_CHARACTERS }}
                      disabled={!allowEdit}
                      id="title"
                      variant="outlined"
                      size="small"
                      margin="normal"
                      onChange={handleChange('title')}
                      value={values.title}
                      error={touched.title && !!errors.title}
                      helperText={(touched.title && errors.title) || ''}
                      onBlur={() => setFieldTouched('title')}
                    />
                    <FormControlLabel
                      className={styles.label}
                      control={
                        <Switch color="primary" checked={values.hideTitle} onChange={handleChange('hideTitle')} />
                      }
                      label="Hide title"
                      disabled={!allowEdit}
                    />
                  </Grid>
                </Grid>
                <TextField
                  name="framework"
                  label="Framework"
                  id="framework"
                  variant="outlined"
                  fullWidth
                  disabled={!allowEdit}
                  size="small"
                  margin="normal"
                  onChange={handleChange('framework')}
                  value={values.framework}
                  error={touched.framework && !!errors.framework}
                  helperText={(touched.framework && errors.framework) || ''}
                  onBlur={() => setFieldTouched('framework')}
                />
                <FormControlLabel
                  className={styles.label}
                  control={
                    <Checkbox color="primary" checked={values.labelAdults} onChange={handleChange('labelAdults')} />
                  }
                  label="Adults"
                  disabled={!allowEdit}
                />
                <FormControlLabel
                  className={styles.label}
                  control={<Checkbox color="primary" checked={values.labelKids} onChange={handleChange('labelKids')} />}
                  label="Kids"
                  disabled={!allowEdit}
                />
                <MultiSelector
                  options={allTopics || []}
                  selected={values.topics}
                  onChange={(selected) => setFieldValue('topics', selected)}
                  disabled={!allowEdit}
                />
                <TextField
                  name="objective"
                  label="Learning goal shown to the tutor"
                  id="objective"
                  variant="outlined"
                  fullWidth
                  multiline
                  required
                  minRows={4}
                  disabled={!allowEdit}
                  size="small"
                  margin="normal"
                  onChange={handleChange('objective')}
                  value={values.objective}
                  error={touched.objective && !!errors.objective}
                  helperText={(touched.objective && errors.objective) || ''}
                  onBlur={() => setFieldTouched('objective')}
                />
                <TextField
                  name="learnerObjective"
                  label="Learning goal shown to the user"
                  id="learnerObjective"
                  variant="outlined"
                  fullWidth
                  multiline
                  required
                  minRows={2}
                  disabled={!allowEdit}
                  size="small"
                  margin="normal"
                  onChange={handleChange('learnerObjective')}
                  value={values.learnerObjective}
                  error={touched.learnerObjective && !!errors.learnerObjective}
                  helperText={(touched.learnerObjective && errors.learnerObjective) || ''}
                  onBlur={() => setFieldTouched('learnerObjective')}
                />
                <TextField
                  name="attribution"
                  label="Attribution"
                  id="attribution"
                  variant="outlined"
                  fullWidth
                  multiline
                  minRows={3}
                  disabled={!allowEdit}
                  size="small"
                  margin="normal"
                  onChange={handleChange('attribution')}
                  value={values.attribution}
                  error={touched.attribution && !!errors.attribution}
                  helperText={
                    (touched.attribution && errors.attribution) ||
                    'How to add a link: [Hyperlink text](http://www.example.com)'
                  }
                  onBlur={() => setFieldTouched('attribution')}
                />
              </Grid>
              <Grid item xs={4}>
                <div className={styles.imageWrapper}>
                  <span>Study set image</span>
                  <ImageField
                    showAlert={showAlert}
                    data-testid="studySetCoverImage"
                    name="image"
                    disabled={!allowEdit}
                    style={{ width: 224, height: 224 }}
                    value={values.image.url}
                    onChange={handleChange('image')}
                    onOriginalChange={handleChange('imageOriginal')}
                    originalValue={values.imageOriginal.url}
                    onBlur={() => {
                      setFieldTouched('image')
                      setFieldTouched('imageOriginal')
                    }}
                    cropperProps={{ locked: false }}
                    aspectRatio={224 / 224}
                    width={224}
                    height={null}
                  />
                </div>
              </Grid>
            </Grid>
            <div className={styles.formButtons}>
              {allowEdit && (
                <Button
                  disabled={isSubmitting || isPending || !dirty}
                  color="primary"
                  variant="contained"
                  type="submit"
                  onClick={() => handleSubmit()}>
                  {isNew ? 'Add study set to library' : 'Save info'}
                </Button>
              )}

              {!isNew && (
                <>
                  <ConfirmPopup
                    disabled={isPublishDisabled}
                    onConfirm={handlePublishing}
                    text={`Are you sure you want to ${studySet?.active ? 'unpublish' : 'publish'} the study set?`}>
                    <Button disabled={isPublishDisabled} color="primary" variant="contained">
                      {studySet?.active ? 'Unpublish' : 'Publish'}
                    </Button>
                  </ConfirmPopup>
                  <Button onClick={duplicate} disabled={isSubmitting || isPending} color="primary" variant="outlined">
                    Duplicate
                  </Button>
                </>
              )}

              {!isNew && allowEdit && (
                <ConfirmPopup
                  onConfirm={handleDelete}
                  disabled={isSubmitting || isPending}
                  text="Are you sure you want to delete the Study Set?"
                  confirmText="Yes, delete the Study Set"
                  cancelText="No">
                  <Button disabled={isSubmitting || isPending} color="primary" variant="outlined">
                    Delete Study Set
                  </Button>
                </ConfirmPopup>
              )}
            </div>
          </>
        )
      }}
    </Formik>
  )
}

export default StudySetForm
