import {
  BOOK_ACTIONS,
  BookActions,
  BookLoadCollection,
  BookLoadCollectionReqState,
  BookModel,
  BookSingleReqState,
  BookSingleSetData,
  BookState
} from './types'
import { Dispatch } from 'redux'
import { RootState } from '../../types'
import BookApi from './bookApi'
import { createSelector } from 'reselect'
import { Logger } from '../../../shared/logger/Logger'
import { ApiReqState, Paginated, PaginatedParams } from '../../../shared/api/types'
import { ThunkAction } from 'redux-thunk'

export const BookInitialState: BookState = {
  collection: {
    results: [],
    status: undefined,
    reqState: ApiReqState.IDLE
  },
  single: {
    data: undefined,
    reqState: ApiReqState.IDLE
  }
}

const bookReducer = (state: BookState = BookInitialState, action: BookActions) => {
  switch (action.type) {
    case BOOK_ACTIONS.LOAD_COLLECTION:
      return {
        ...state,
        collection: {
          ...state.collection,
          ...action.collection
        }
      }
    case BOOK_ACTIONS.LOAD_COLLECTION_REQ_STATE:
      return {
        ...state,
        collection: {
          ...state.collection,
          reqState: action.reqState
        }
      }
    case BOOK_ACTIONS.SINGLE_SET_DATA:
      return {
        ...state,
        single: {
          ...state.single,
          data: action.data
        }
      }
    case BOOK_ACTIONS.SINGLE_REQ_STATE:
      return {
        ...state,
        single: {
          ...state.single,
          reqState: action.reqState
        }
      }
    default:
      return state
  }
}

export default bookReducer

// ACTIONS
const setBookLoadReqState = (reqState: ApiReqState): BookLoadCollectionReqState => ({
  type: BOOK_ACTIONS.LOAD_COLLECTION_REQ_STATE,
  reqState
})

const setBookLoadCollection = (collection: Paginated<BookModel>): BookLoadCollection => ({
  type: BOOK_ACTIONS.LOAD_COLLECTION,
  collection
})

export const getBookCollection = (params?: PaginatedParams) => async (
  dispatch: Dispatch<BookLoadCollection | BookLoadCollectionReqState>
) => {
  try {
    dispatch(setBookLoadReqState(ApiReqState.PENDING))

    const collection = (await BookApi.load(params)).data
    dispatch(setBookLoadCollection(collection))

    dispatch(setBookLoadReqState(ApiReqState.RESOLVED))
  } catch (e) {
    dispatch(setBookLoadReqState(ApiReqState.REJECTED))
    Logger.log(e)
    throw new Error(e)
  }
}

export const setSingleBookData = (data: BookModel): BookSingleSetData => ({
  type: BOOK_ACTIONS.SINGLE_SET_DATA,
  data
})

export const setSingleBookReqState = (reqState: ApiReqState): BookSingleReqState => ({
  type: BOOK_ACTIONS.SINGLE_REQ_STATE,
  reqState
})

export const getBookSingle = (id: BookModel['_id']) => async (
  dispatch: Dispatch<BookSingleSetData | BookSingleReqState>
): Promise<BookModel> => {
  try {
    dispatch(setSingleBookReqState(ApiReqState.PENDING))

    const { data } = await BookApi.loadSingle(id)
    dispatch(setSingleBookData(data))

    dispatch(setSingleBookReqState(ApiReqState.RESOLVED))
    return data
  } catch (e) {
    dispatch(setSingleBookReqState(ApiReqState.REJECTED))
    Logger.log(e)
    throw new Error(e)
  }
}

export const createBookSingle = (bookData: FormData) => async (
  dispatch: Dispatch<BookSingleSetData | BookSingleReqState>
): Promise<BookModel> => {
  try {
    dispatch(setSingleBookReqState(ApiReqState.PENDING))
    const { data } = await BookApi.create(bookData)
    dispatch(setSingleBookData(data))

    dispatch(setSingleBookReqState(ApiReqState.RESOLVED))
    return data
  } catch (e) {
    dispatch(setSingleBookReqState(ApiReqState.REJECTED))
    Logger.log(e)
    throw new Error(e)
  }
}

export const updateBookSingle = (
  bookData: FormData,
  id: string
): ThunkAction<Promise<BookModel>, RootState, unknown, BookSingleSetData | BookSingleReqState> => async (
  dispatch
): Promise<BookModel> => {
  try {
    dispatch(setSingleBookReqState(ApiReqState.PENDING))
    const { data } = await BookApi.update(bookData, id)
    dispatch(setSingleBookData(data))

    dispatch(setSingleBookReqState(ApiReqState.RESOLVED))
    return data
  } catch (e) {
    dispatch(setSingleBookReqState(ApiReqState.REJECTED))
    Logger.log(e)
    throw new Error(e)
  }
}

export const publishBookSingle = (id: string) => async (
  dispatch: Dispatch<BookSingleSetData | BookSingleReqState>
): Promise<BookModel> => {
  try {
    dispatch(setSingleBookReqState(ApiReqState.PENDING))
    const { data } = await BookApi.publish(id)
    dispatch(setSingleBookData(data))

    dispatch(setSingleBookReqState(ApiReqState.RESOLVED))
    return data
  } catch (e) {
    dispatch(setSingleBookReqState(ApiReqState.REJECTED))
    Logger.log(e)
    throw new Error(e)
  }
}

export const unpublishBookSingle = (id: string) => async (
  dispatch: Dispatch<BookSingleSetData | BookSingleReqState>
): Promise<BookModel> => {
  try {
    dispatch(setSingleBookReqState(ApiReqState.PENDING))
    const { data } = await BookApi.unpublish(id)
    dispatch(setSingleBookData(data))

    dispatch(setSingleBookReqState(ApiReqState.RESOLVED))
    return data
  } catch (e) {
    dispatch(setSingleBookReqState(ApiReqState.REJECTED))
    Logger.log(e)
    throw new Error(e)
  }
}

// SELECTORS
export const selectBookCollection = (state: RootState) => state.book.collection
export const selectBooks = createSelector(selectBookCollection, (collection) => collection.results)
export const selectBooksStatus = createSelector(selectBookCollection, (collection) => collection.status)
export const selectBooksReqState = createSelector(selectBookCollection, (collection) => collection.reqState)

export const selectBookSingle = (state: RootState) => state.book.single
export const selectBookSingleData = createSelector(
  selectBookSingle,
  (_: RootState, id: string) => id,
  (single, id) => (single.data?._id === id ? single.data : BookInitialState.single.data)
)
