import * as React from 'react'
import { NavLink, NavLinkProps } from 'react-router-dom'
import styles from './NavButton.module.scss'

const NavButton: React.FC<NavLinkProps & { withoutActiveStatus?: boolean }> = ({
  children,
  className,
  withoutActiveStatus = false,
  ...props
}) => (
  <NavLink
    className={`${styles.NavButton} ${className}`}
    activeClassName={!withoutActiveStatus ? styles.NavButtonActive : ''}
    {...props}>
    {children}
  </NavLink>
)

export default NavButton
