import {
  StudentState,
  STUDENT_ACTIONS,
  StudentActions,
  StudentLoadCollectionReqState,
  StudentModel,
  StudentLoadCollection
} from './types'
import { Dispatch } from 'redux'
import { RootState } from '../../types'
import { createSelector } from 'reselect'
import { Logger } from '../../../shared/logger/Logger'
import { ApiReqState, Paginated, PaginatedParams } from '../../../shared/api/types'
import StudentApi from './studentApi'

export const StudentInitialState: StudentState = {
  collection: {
    results: [],
    status: undefined,
    reqState: ApiReqState.IDLE
  }
}

const studentReducer = (state: StudentState = StudentInitialState, action: StudentActions) => {
  switch (action.type) {
    case STUDENT_ACTIONS.LOAD_COLLECTION:
      return {
        ...state,
        collection: {
          ...state.collection,
          ...action.collection
        }
      }
    case STUDENT_ACTIONS.LOAD_COLLECTION_REQ_STATE:
      return {
        ...state,
        collection: {
          ...state.collection,
          reqState: action.reqState
        }
      }
    default:
      return state
  }
}

export default studentReducer

// ACTIONS
const setStudentLoadReqState = (reqState: ApiReqState): StudentLoadCollectionReqState => ({
  type: STUDENT_ACTIONS.LOAD_COLLECTION_REQ_STATE,
  reqState
})

const setStudentLoadCollection = (collection: Paginated<StudentModel>): StudentLoadCollection => ({
  type: STUDENT_ACTIONS.LOAD_COLLECTION,
  collection
})

export const getStudentCollection = (params?: PaginatedParams) => async (
  dispatch: Dispatch<StudentLoadCollection | StudentLoadCollectionReqState>
) => {
  try {
    dispatch(setStudentLoadReqState(ApiReqState.PENDING))

    const collection = (await StudentApi.load(params)).data
    dispatch(setStudentLoadCollection(collection))

    dispatch(setStudentLoadReqState(ApiReqState.RESOLVED))
  } catch (e) {
    dispatch(setStudentLoadReqState(ApiReqState.REJECTED))
    Logger.log(e)
    throw new Error(e)
  }
}

// SELECTORS
export const selectStudentCollection = (state: RootState) => state.student.collection
export const selectStudents = createSelector(selectStudentCollection, (collection) => collection.results)
export const selectStudentsStatus = createSelector(selectStudentCollection, (collection) => collection.status)
export const selectStudentsReqState = createSelector(selectStudentCollection, (collection) => collection.reqState)
