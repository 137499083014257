import * as React from 'react'
import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography
} from '@material-ui/core'
import DashboardToolbar from '../Dashboard/DashboardToolbar/DashboardToolbar'
import { RouteComponentProps } from 'react-router-dom'
import SearchField from '../shared/components/SearchField/SearchField'
import { connect, ConnectedProps } from 'react-redux'
import styles from './SchoolsTable.module.scss'
import { ApiReqState, ApiSortModel } from '../shared/api/types'
import { RootState } from '../store/types'
import { ArrowDropDown } from '@material-ui/icons'
import CollectionQueryHelper from '../shared/helpers/CollectionQueryHelper'
import TableOverlay from '../shared/components/TableOverlay/TableOverlay'
import {
  selectSchools,
  selectSchoolsStatus,
  selectSchoolsReqState,
  getSchoolCollection
} from '../store/services/School/schoolReducer'
import { SchoolModel } from '../store/services/School/types'

interface State {
  sortParams: ApiSortModel
  searchQuery: string
}

interface Props extends RouteComponentProps, ConnectedProps<typeof connector> {}

export const SCHOOLS_PER_PAGE = 10

class SchoolsTable extends React.Component<Props, State> {
  state: State = {
    sortParams: {},
    searchQuery: ''
  }

  componentDidMount(): void {
    this.loadSchools()
  }

  loadSchools = async (page: number = 0) => {
    try {
      const { searchQuery, sortParams } = this.state
      await this.props.getSchoolCollection({ limit: SCHOOLS_PER_PAGE, page, sort: sortParams, search: searchQuery })
    } catch (e) {}
  }

  handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    this.loadSchools(newPage)
  }

  handleSortRequest = (key: keyof SchoolModel) => (e: React.MouseEvent<unknown>) => {
    this.setState(
      (state) => ({ sortParams: CollectionQueryHelper.setSortValue(key, state.sortParams) }),
      () => {
        this.loadSchools(0)
      }
    )
  }

  handleSearchQuery = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchQuery: e.target.value }, () => {
      this.loadSchools(0)
    })
  }

  render() {
    const { schools, schoolsResStatus, schoolReqState } = this.props
    const { sortParams, searchQuery } = this.state
    const emptyRows = SCHOOLS_PER_PAGE - schools.length

    const isLoading = schoolReqState === ApiReqState.IDLE || schoolReqState === ApiReqState.PENDING

    return (
      <>
        <DashboardToolbar>
          <Typography component="h1" variant="h6">
            Schools
          </Typography>
          <SearchField
            containerClassName={styles.searchField}
            value={searchQuery}
            onChange={this.handleSearchQuery}
            onClear={() => this.handleSearchQuery({ target: { value: '' } } as React.ChangeEvent<HTMLInputElement>)}
          />
        </DashboardToolbar>
        <TableContainer component={Paper}>
          {!isLoading && schools.length === 0 && (
            <TableOverlay>
              <Typography>Schools not found.</Typography>
            </TableOverlay>
          )}
          {isLoading && (
            <TableOverlay data-testid="SchoolsTableLoader">
              <CircularProgress size={40} />
            </TableOverlay>
          )}
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell style={{ width: 420 }}>
                  <TableSortLabel
                    data-testid={'SortByNameSchoolLabel'}
                    active={CollectionQueryHelper.isSortActive('name', sortParams)}
                    direction={CollectionQueryHelper.printSortDirection('name', sortParams)}
                    onClick={this.handleSortRequest('name')}
                    IconComponent={ArrowDropDown}>
                    Name
                  </TableSortLabel>
                </TableCell>
                <TableCell style={{ width: 240 }}>
                  <TableSortLabel
                    active={CollectionQueryHelper.isSortActive('country', sortParams)}
                    direction={CollectionQueryHelper.printSortDirection('country', sortParams)}
                    onClick={this.handleSortRequest('country')}
                    IconComponent={ArrowDropDown}>
                    Country
                  </TableSortLabel>
                </TableCell>
                <TableCell style={{ width: 240 }}>
                  <TableSortLabel
                    active={CollectionQueryHelper.isSortActive('city', sortParams)}
                    direction={CollectionQueryHelper.printSortDirection('city', sortParams)}
                    onClick={this.handleSortRequest('city')}
                    IconComponent={ArrowDropDown}>
                    City
                  </TableSortLabel>
                </TableCell>
                <TableCell style={{ width: 240 }}>
                  <TableSortLabel
                    active={CollectionQueryHelper.isSortActive('teachersCount', sortParams)}
                    direction={CollectionQueryHelper.printSortDirection('teachersCount', sortParams)}
                    onClick={this.handleSortRequest('teachersCount')}
                    IconComponent={ArrowDropDown}>
                    No of teachers
                  </TableSortLabel>
                </TableCell>
                <TableCell style={{ width: 240 }}>
                  <TableSortLabel
                    active={CollectionQueryHelper.isSortActive('studentsCount', sortParams)}
                    direction={CollectionQueryHelper.printSortDirection('studentsCount', sortParams)}
                    onClick={this.handleSortRequest('studentsCount')}
                    IconComponent={ArrowDropDown}>
                    No of students
                  </TableSortLabel>
                </TableCell>
                <TableCell style={{ width: 240 }}>
                  <TableSortLabel
                    active={CollectionQueryHelper.isSortActive('classCount', sortParams)}
                    direction={CollectionQueryHelper.printSortDirection('classCount', sortParams)}
                    onClick={this.handleSortRequest('classCount')}
                    IconComponent={ArrowDropDown}>
                    No of classes
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {schools.map((school) => (
                <TableRow
                  hover
                  key={school.name}
                  // onClick={() => history.push(`${ROUTES.SCHOOL}/${school._id}`)}
                  data-testid={'SchoolListRow'}>
                  <TableCell component="th" scope="row">
                    {school.name}
                  </TableCell>

                  <TableCell>{school.country}</TableCell>
                  <TableCell>{school.city}</TableCell>
                  <TableCell>{school.teachersCount}</TableCell>
                  <TableCell>{school.studentsCount}</TableCell>
                  <TableCell>{school.classCount}</TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 59 * emptyRows }} data-testid={'SchoolListEmptyRow'}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          {schoolsResStatus ? (
            <TablePagination
              component="div"
              colSpan={3}
              count={schoolsResStatus.total}
              rowsPerPage={SCHOOLS_PER_PAGE}
              rowsPerPageOptions={[]}
              page={schoolsResStatus.offset / SCHOOLS_PER_PAGE}
              onPageChange={this.handleChangePage}
            />
          ) : (
            <span>Not loaded yet</span>
          )}
        </TableContainer>
      </>
    )
  }
}

const connector = connect(
  (state: RootState) => ({
    schools: selectSchools(state),
    schoolsResStatus: selectSchoolsStatus(state),
    schoolReqState: selectSchoolsReqState(state)
  }),
  { getSchoolCollection }
)

export default connector(SchoolsTable)
