import { CEFRLevels } from '@astrid/components'
import {
  Button,
  CircularProgress,
  debounce,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography
} from '@material-ui/core'
import { ArrowDropDown, Visibility, VisibilityOff } from '@material-ui/icons'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import DashboardToolbar from '../../Dashboard/DashboardToolbar/DashboardToolbar'
import { ROUTES } from '../../routes/routes'
import { ApiReqState } from '../../shared/api/types'
import SearchField from '../../shared/components/SearchField/SearchField'
import SelectCefrLevels from '../../shared/components/SelectCefrLevels/SelectCefrLevels'
import TableOverlay from '../../shared/components/TableOverlay/TableOverlay'
import CollectionQueryHelper from '../../shared/helpers/CollectionQueryHelper'
import { Logger } from '../../shared/logger/Logger'
import {
  getBookCollection,
  selectBooks,
  selectBooksReqState,
  selectBooksStatus
} from '../../store/services/Book/bookReducer'
import { BookModel } from '../../store/services/Book/types'
import { RootState } from '../../store/types'
import styles from './BooksTable.module.scss'
import StatusFilter from './StatusFilter/StatusFilter'

export const BOOKS_PER_PAGE = 10

const BooksTable = () => {
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, AnyAction>>()
  const books = useSelector(selectBooks)
  const booksResStatus = useSelector(selectBooksStatus)
  const bookReqState = useSelector(selectBooksReqState)

  React.useEffect(() => {
    ;(async () => {
      try {
        const params = {
          limit: BOOKS_PER_PAGE,
          ...CollectionQueryHelper.getParamsFromQueryString(location.search)
        }
        await dispatch(getBookCollection(params))
      } catch (error) {
        Logger.log(error)
      }
    })()
  }, [location.search, dispatch])

  const handleCefrFilter = async (cefrLevels: CEFRLevels[]) => {
    const params = CollectionQueryHelper.buildQueryString(location.search, { cefrLevel: cefrLevels }, true)
    history.replace({ search: params })
  }

  const handleStatusFilter = async (visible: boolean | null) => {
    const params = CollectionQueryHelper.buildQueryString(location.search, { visible }, true)
    history.replace({ search: params })
  }

  const handleChangePage = (page: number) => {
    const params = CollectionQueryHelper.buildQueryString(location.search, { page })
    history.replace({ search: params })
  }

  const handleSort = (key: keyof BookModel) => () => {
    const sort = CollectionQueryHelper.getToggledSortValueAsQueryString(key, location.search)
    const newParams = CollectionQueryHelper.buildQueryString(location.search, { sort: sort })
    history.replace({ search: newParams })
  }

  const handleSearchQuery = debounce((search: string) => {
    const params = CollectionQueryHelper.buildQueryString(history.location.search, { search }, true)
    history.replace({ search: params })
  }, 300)

  const emptyRows = BOOKS_PER_PAGE - books.length
  const { sort, filter, search } = CollectionQueryHelper.getParamsFromQueryString(location.search)

  const isLoading = bookReqState === ApiReqState.IDLE || bookReqState === ApiReqState.PENDING

  return (
    <>
      <DashboardToolbar>
        <Typography component="h1" variant="h6">
          Books
        </Typography>
        <Button
          color={'primary'}
          variant={'contained'}
          className={styles.addButton}
          onClick={() => history.push(`${ROUTES.BOOK}/new`)}>
          Add new
        </Button>
        <SearchField
          containerClassName={styles.searchField}
          defaultValue={search}
          onChange={(e) => handleSearchQuery(e.target.value)}
          onClear={() => handleSearchQuery('')}
        />
      </DashboardToolbar>
      <TableContainer component={Paper}>
        {!isLoading && books.length === 0 && (
          <TableOverlay>
            <Typography>Books not found.</Typography>
          </TableOverlay>
        )}
        {isLoading && (
          <TableOverlay data-testid="BooksTableLoader">
            <CircularProgress size={40} />
          </TableOverlay>
        )}
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <TableCell style={{ width: 420 }}>
                <TableSortLabel
                  data-testid={'SortByTitleBookLabel'}
                  active={CollectionQueryHelper.isSortActive('title', sort)}
                  direction={CollectionQueryHelper.printSortDirection('title', sort)}
                  onClick={handleSort('title')}
                  IconComponent={ArrowDropDown}>
                  Book name
                </TableSortLabel>
              </TableCell>
              <TableCell style={{ width: 240 }}>
                <TableSortLabel
                  active={CollectionQueryHelper.isSortActive('author', sort)}
                  direction={CollectionQueryHelper.printSortDirection('author', sort)}
                  onClick={handleSort('author')}
                  IconComponent={ArrowDropDown}>
                  Author
                </TableSortLabel>
              </TableCell>
              <TableCell align="center">
                <TableSortLabel
                  active={CollectionQueryHelper.isSortActive('cefrLevel', sort)}
                  direction={CollectionQueryHelper.printSortDirection('cefrLevel', sort)}
                  onClick={handleSort('cefrLevel')}
                  IconComponent={ArrowDropDown}>
                  CEFR
                </TableSortLabel>
                <SelectCefrLevels
                  selectedCefrLevels={filter?.cefrLevel || []}
                  onSelectedCefrLevelsChange={(cefrLevels) => handleCefrFilter(cefrLevels)}></SelectCefrLevels>
              </TableCell>
              <TableCell align="center">
                Status
                <StatusFilter
                  status={filter?.visible ?? null}
                  onSetStatusChange={(status) => handleStatusFilter(status)}></StatusFilter>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {books.map((book) => (
              <TableRow
                hover
                key={book._id}
                onClick={() => history.push(`${ROUTES.BOOK}/${book._id}`)}
                data-testid={'BookListRow'}>
                <TableCell component="th" scope="row">
                  {book.titleUs || book.title}
                </TableCell>
                <TableCell>{book.author}</TableCell>
                <TableCell align="center">{book.cefrLevel}</TableCell>
                <TableCell align="center">
                  {book.visible ? (
                    <Visibility color={'secondary'} fontSize={'small'} />
                  ) : (
                    <VisibilityOff color={'disabled'} fontSize={'small'} />
                  )}
                </TableCell>
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 59 * emptyRows }} data-testid={'BookListEmptyRow'}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        {booksResStatus ? (
          <TablePagination
            component="div"
            colSpan={3}
            count={booksResStatus.total}
            rowsPerPage={BOOKS_PER_PAGE}
            rowsPerPageOptions={[]}
            page={booksResStatus.offset / BOOKS_PER_PAGE}
            onPageChange={(_, page) => handleChangePage(page)}
          />
        ) : (
          <span>Not loaded yet</span>
        )}
      </TableContainer>
    </>
  )
}

export default BooksTable
