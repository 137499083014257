/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react'
import { Formik, FormikHelpers } from 'formik'
import { Container, TextField, Button, Avatar, Typography, CircularProgress } from '@material-ui/core'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import * as Yup from 'yup'
import { ValidationRules } from '../../shared/helpers/ValidationRules'
import { AuthCredentials } from '../../store/services/Auth/types'
import { useDispatch } from 'react-redux'
import { loginUser } from '../../store/services/Auth/authReducer'
import styles from './Login.module.scss'
import { useCallback, useContext } from 'react'
import { printApiMessage } from '../../shared/api/apiMessages'
import { AlertContext } from '../../shared/components/AlertContext/AlertContext'

const validationSchema = Yup.object().shape({
  email: ValidationRules.email,
  password: ValidationRules.required
})

const Login: React.FC = () => {
  const { showAlert } = useContext(AlertContext)

  const dispatch = useDispatch()
  const handleFormSubmit = useCallback(
    async ({ email, password }: AuthCredentials, { setSubmitting }: FormikHelpers<AuthCredentials>) => {
      try {
        await dispatch(loginUser({ email, password }))
      } catch (error) {
        showAlert(printApiMessage(error))
        setSubmitting(false)
      }
    },
    // eslint-disable-next-line
    []
  )

  return (
    <Container data-testid={'LoginPage'} className={styles.root}>
      <header className={styles.header}>
        <Avatar className={styles.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
      </header>
      <Formik
        initialValues={{ email: '', password: '' }}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}>
        {({ handleChange, handleSubmit, values, errors, touched, setFieldTouched, isSubmitting, isValid, dirty }) => (
          <>
            <form onSubmit={handleSubmit}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
                size="small"
                onChange={handleChange('email')}
                value={values.email}
                error={touched.email && !!errors.email}
                helperText={(touched.email && errors.email) || ''}
                onBlur={() => setFieldTouched('email')}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                size="small"
                onChange={handleChange('password')}
                value={values.password}
                error={touched.password && !!errors.password}
                helperText={(touched.password && errors.password) || ''}
                onBlur={() => setFieldTouched('password')}
              />
              <Button
                data-testid={'submit'}
                type="submit"
                variant={'contained'}
                color={'primary'}
                fullWidth
                disabled={!isValid || !dirty || isSubmitting}>
                {isSubmitting ? <CircularProgress size={24} color={'inherit'} /> : 'Log in'}
              </Button>
            </form>
          </>
        )}
      </Formik>
    </Container>
  )
}
export default Login
