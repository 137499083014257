import { AxiosPromise } from 'axios'
import API from '../../../shared/api/API'
import { Paginated, PaginatedParams } from '../../../shared/api/types'
import { cleanObject } from '../../../shared/helpers/cleanObject'
import CollectionQueryHelper from '../../../shared/helpers/CollectionQueryHelper'
import { BookModel } from './types'

type BookLoadResponse = Paginated<BookModel>

class BookApi {
  static load = ({ limit = 10, page = 0, sort, search, filter }: PaginatedParams = {}): AxiosPromise<
    BookLoadResponse
  > =>
    API.get(`/book`, {
      params: cleanObject({
        $limit: limit,
        $skip: limit * page,
        $sort: sort && CollectionQueryHelper.sortParamsToQueryString(sort),
        $search: search?.toLocaleLowerCase(),
        $filter: filter && CollectionQueryHelper.filterParamsToQueryString(filter)
      })
    })

  static loadSingle = (id: BookModel['_id']): AxiosPromise<BookModel> => API.get(`/book/${id}`)

  static create = (data: FormData): AxiosPromise<BookModel> => API.post('/book', data)

  static update = (data: FormData, id: string): AxiosPromise<BookModel> => API.put(`/book/${id}`, data)

  static publish = (id: string): AxiosPromise<BookModel> => API.patch(`/book/${id}`, { visible: true })

  static unpublish = (id: string): AxiosPromise<BookModel> => API.patch(`/book/${id}`, { visible: false })

  static delete = (id: string): AxiosPromise<BookModel> => API.delete(`/book/${id}`)

  static duplicate = (id: string): AxiosPromise<BookModel> => API.post(`/book/${id}/duplicate`)

  static copy = (id: string): AxiosPromise<BookModel> => API.post(`/book/${id}/copy`)
}

export default BookApi
