import { Dispatch } from 'redux'
import { ApiReqState } from '../../../shared/api/types'
import { Logger } from '../../../shared/logger/Logger'
import { RootState } from '../../types'
import TopicsApi from './topicsApi'
import {
  TopicsActions,
  TopicsModel,
  TopicsReqState,
  TOPICS_ACTIONS,
  ContentType,
  TopicsState,
  TopicsSetData
} from './types'

export const TopicsInitialState: TopicsState = {
  data: undefined,
  reqState: ApiReqState.IDLE
}

const topicsReducer = (state: TopicsState = TopicsInitialState, action: TopicsActions) => {
  switch (action.type) {
    case TOPICS_ACTIONS.SET_DATA:
      return {
        ...state,
        data: action.data
      }
    case TOPICS_ACTIONS.REQ_STATE:
      return {
        ...state,
        reqState: action.reqState
      }
    default:
      return state
  }
}

export default topicsReducer

// ACTIONS
const setTopicsData = (data: TopicsModel): TopicsSetData => ({
  type: TOPICS_ACTIONS.SET_DATA,
  data
})
const setTopicsReqState = (reqState: ApiReqState): TopicsReqState => ({
  type: TOPICS_ACTIONS.REQ_STATE,
  reqState
})

export const getTopics = ({ contentId, contentType }: Omit<TopicsModel, 'topics'>) => async (
  dispatch: Dispatch<TopicsSetData | TopicsReqState>
) => {
  try {
    dispatch(setTopicsReqState(ApiReqState.PENDING))

    const { data } = await TopicsApi.load({ contentId, contentType })

    dispatch(setTopicsData({ contentId, contentType, topics: data }))
    dispatch(setTopicsReqState(ApiReqState.RESOLVED))
  } catch (e) {
    dispatch(setTopicsReqState(ApiReqState.REJECTED))
    Logger.log(e)
  }
}

export const updateTopics = ({ contentId, contentType, topics }: TopicsModel) => async (
  dispatch: Dispatch<TopicsSetData | TopicsReqState>
) => {
  try {
    dispatch(setTopicsReqState(ApiReqState.PENDING))

    const { data } = await TopicsApi.update({ contentId, contentType, topics })

    dispatch(setTopicsData({ contentId, contentType, topics: data }))
    dispatch(setTopicsReqState(ApiReqState.RESOLVED))
  } catch (e) {
    dispatch(setTopicsReqState(ApiReqState.REJECTED))
    Logger.log(e)
  }
}

// SELECTORS
export const selectTopics = (state: RootState, contentId: string, contentType: ContentType) =>
  state.topics.data?.contentId === contentId && state.topics.data?.contentType === contentType
    ? state.topics.data.topics
    : []
export const selectTopicsReqState = (state: RootState) => state.topics.reqState
