import { Dispatch } from 'redux'
import { RootState } from '../../types'
import { createSelector } from 'reselect'
import { Logger } from '../../../shared/logger/Logger'
import { ApiReqState, Paginated, PaginatedParams } from '../../../shared/api/types'
import {
  SchoolState,
  SchoolActions,
  SCHOOL_ACTIONS,
  SchoolModel,
  SchoolLoadCollectionReqState,
  SchoolLoadCollection
} from './types'
import SchoolApi from './schoolApi'

export const SchoolInitialState: SchoolState = {
  collection: {
    results: [],
    status: undefined,
    reqState: ApiReqState.IDLE
  }
}

const schoolReducer = (state: SchoolState = SchoolInitialState, action: SchoolActions) => {
  switch (action.type) {
    case SCHOOL_ACTIONS.LOAD_COLLECTION:
      return {
        ...state,
        collection: {
          ...state.collection,
          ...action.collection
        }
      }
    case SCHOOL_ACTIONS.LOAD_COLLECTION_REQ_STATE:
      return {
        ...state,
        collection: {
          ...state.collection,
          reqState: action.reqState
        }
      }
    default:
      return state
  }
}

export default schoolReducer

// ACTIONS
const setSchoolLoadReqState = (reqState: ApiReqState): SchoolLoadCollectionReqState => ({
  type: SCHOOL_ACTIONS.LOAD_COLLECTION_REQ_STATE,
  reqState
})

const setSchoolLoadCollection = (collection: Paginated<SchoolModel>): SchoolLoadCollection => ({
  type: SCHOOL_ACTIONS.LOAD_COLLECTION,
  collection
})

export const getSchoolCollection = (params?: PaginatedParams) => async (
  dispatch: Dispatch<SchoolLoadCollection | SchoolLoadCollectionReqState>
) => {
  try {
    dispatch(setSchoolLoadReqState(ApiReqState.PENDING))

    const collection = (await SchoolApi.load(params)).data
    dispatch(setSchoolLoadCollection(collection))

    dispatch(setSchoolLoadReqState(ApiReqState.RESOLVED))
  } catch (e) {
    dispatch(setSchoolLoadReqState(ApiReqState.REJECTED))
    Logger.log(e)
    throw new Error(e)
  }
}

// SELECTORS
export const selectSchoolCollection = (state: RootState) => state.school.collection
export const selectSchools = createSelector(selectSchoolCollection, (collection) => collection.results)
export const selectSchoolsStatus = createSelector(selectSchoolCollection, (collection) => collection.status)
export const selectSchoolsReqState = createSelector(selectSchoolCollection, (collection) => collection.reqState)
