import API, { ACCESS_TOKEN_KEY } from '../../../shared/api/API'
import { AdminData, AuthCredentials } from './types'
import { AxiosPromise } from 'axios'

interface AuthorizationResponse {
  user: AdminData
  token: string
}

class AuthApi {
  static getUserToken = () => localStorage.getItem(ACCESS_TOKEN_KEY)
  static setUserToken = (token: string) => localStorage.setItem(ACCESS_TOKEN_KEY, token)
  static removeUserToken = () => localStorage.removeItem(ACCESS_TOKEN_KEY)
  static login = (data: AuthCredentials): AxiosPromise<AuthorizationResponse> => API.post('/auth/admin', data)
  static load = (): AxiosPromise<AuthorizationResponse> => API.get('/auth/admin')
}

export default AuthApi
