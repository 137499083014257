export interface AuthState {
  user?: AdminData
}

export enum AUTH_ACTIONS {
  LOAD_DATA = 'AUTH_LOAD_DATA'
}

export interface AuthCredentials {
  email: string
  password: string
}

export enum AuthRoles {
  Admin = 'admin',
  User = 'user'
}

export interface AdminData {
  _id: string
  email: string
  role: AuthRoles.Admin
}

export interface AuthLoadUser {
  type: AUTH_ACTIONS.LOAD_DATA
  user: AdminData
}

export type AuthActions = AuthLoadUser
