import { CEFRLevels } from '@astrid/components'
import {
  Button,
  CircularProgress,
  debounce,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography
} from '@material-ui/core'
import { ArrowDropDown, Visibility, VisibilityOff } from '@material-ui/icons'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import DashboardToolbar from '../../Dashboard/DashboardToolbar/DashboardToolbar'
import { ROUTES } from '../../routes/routes'
import { ApiReqState, PaginatedStatus } from '../../shared/api/types'
import SearchField from '../../shared/components/SearchField/SearchField'
import SelectCefrLevels from '../../shared/components/SelectCefrLevels/SelectCefrLevels'
import TableOverlay from '../../shared/components/TableOverlay/TableOverlay'
import CollectionQueryHelper from '../../shared/helpers/CollectionQueryHelper'
import { Logger } from '../../shared/logger/Logger'
import {
  getStudySetCollection,
  selectStudySets,
  selectStudySetsReqState,
  selectStudySetsStatus
} from '../../store/services/StudySet/studySetReducer'
import { StudySetModel } from '../../store/services/StudySet/types'
import { RootState } from '../../store/types'
import styles from './StudySetsTable.module.scss'

export const STUDY_SETS_PER_PAGE = 10
const NEW_ID = 'new'

const StudySetsTable = () => {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, AnyAction>>()

  const studySets = useSelector<RootState, StudySetModel[]>((state) => selectStudySets(state))
  const studySetsResStatus = useSelector<RootState, PaginatedStatus | undefined>((state) =>
    selectStudySetsStatus(state)
  )
  const studySetsReqState = useSelector<RootState, ApiReqState>((state) => selectStudySetsReqState(state))

  useEffect(() => {
    ;(async () => {
      try {
        await dispatch(
          getStudySetCollection({
            limit: STUDY_SETS_PER_PAGE,
            ...CollectionQueryHelper.getParamsFromQueryString(location.search)
          })
        )
      } catch (e) {
        Logger.log(e)
      }
    })()
  }, [location.search, dispatch])

  const handleSortRequest = (key: keyof StudySetModel) => () => {
    const sort = CollectionQueryHelper.getToggledSortValueAsQueryString(key, location.search)
    const newParams = CollectionQueryHelper.buildQueryString(location.search, { sort: sort })
    history.replace({ search: newParams })
  }

  const handleCefrFilterChange = (cefrLevels: CEFRLevels[]) => {
    const params = CollectionQueryHelper.buildQueryString(location.search, { cefrLevel: cefrLevels }, true)
    history.replace({ search: params })
  }

  const handleSearchQuery = debounce((search: string) => {
    const params = CollectionQueryHelper.buildQueryString(location.search, { search }, true)
    history.replace({ search: params })
  }, 300)

  const handleChangePage = (page: number) => {
    const params = CollectionQueryHelper.buildQueryString(location.search, { page })
    history.replace({ search: params })
  }

  const emptyRows = STUDY_SETS_PER_PAGE - studySets.length
  const isLoading = studySetsReqState === ApiReqState.IDLE || studySetsReqState === ApiReqState.PENDING
  const { sort, filter, search } = CollectionQueryHelper.getParamsFromQueryString(location.search)

  return (
    <>
      <DashboardToolbar>
        <Typography component="h1" variant="h6">
          Study sets
        </Typography>
        <Button
          color="primary"
          variant="contained"
          className={styles.addButton}
          onClick={() => history.push(`${ROUTES.STUDY_SETS}/${NEW_ID}`)}>
          Add new study set
        </Button>
        <SearchField
          containerClassName={styles.searchField}
          defaultValue={search}
          onChange={(e) => handleSearchQuery(e.target.value)}
          onClear={() => handleSearchQuery('')}
        />
      </DashboardToolbar>
      <TableContainer component={Paper}>
        {isLoading && (
          <TableOverlay data-testid="StudySetsTableLoader">
            <CircularProgress size={40} />
          </TableOverlay>
        )}
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <TableCell style={{ width: 240 }}>
                <TableSortLabel
                  data-testid={'SortByFirstNameTutorLabel'}
                  active={CollectionQueryHelper.isSortActive('title', sort)}
                  direction={CollectionQueryHelper.printSortDirection('title', sort)}
                  onClick={handleSortRequest('title')}
                  IconComponent={ArrowDropDown}>
                  Title
                </TableSortLabel>
              </TableCell>
              <TableCell style={{ width: 240 }}>
                <TableSortLabel
                  active={CollectionQueryHelper.isSortActive('learnerObjective', sort)}
                  direction={CollectionQueryHelper.printSortDirection('learnerObjective', sort)}
                  onClick={handleSortRequest('learnerObjective')}
                  IconComponent={ArrowDropDown}>
                  Learning Goal
                </TableSortLabel>
              </TableCell>
              <TableCell style={{ width: 240 }}>
                <TableSortLabel
                  active={CollectionQueryHelper.isSortActive('cefrLevel', sort)}
                  direction={CollectionQueryHelper.printSortDirection('cefrLevel', sort)}
                  onClick={handleSortRequest('cefrLevel')}
                  IconComponent={ArrowDropDown}>
                  CEFR Level
                </TableSortLabel>
                <SelectCefrLevels
                  selectedCefrLevels={filter?.cefrLevel || []}
                  onSelectedCefrLevelsChange={(cefrLevels) => handleCefrFilterChange(cefrLevels)}
                />
              </TableCell>
              <TableCell style={{ width: 100 }}>
                <TableSortLabel
                  active={CollectionQueryHelper.isSortActive('active', sort)}
                  direction={CollectionQueryHelper.printSortDirection('active', sort)}
                  onClick={handleSortRequest('active')}
                  IconComponent={ArrowDropDown}>
                  Status
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {studySets.map((studySet) => (
              <TableRow
                hover
                className={styles.tableRow}
                onClick={() => history.push(`${ROUTES.STUDY_SETS}/${studySet._id}`)}
                key={studySet._id}
                data-testid={'StudySetListRow'}>
                <TableCell component="th" scope="row">
                  {studySet.title}
                </TableCell>

                <TableCell>{studySet.learnerObjective}</TableCell>
                <TableCell>{studySet.cefrLevel}</TableCell>
                <TableCell>
                  {studySet.active ? <Visibility color={'secondary'} /> : <VisibilityOff color={'disabled'} />}
                </TableCell>
              </TableRow>
            ))}
            {!isLoading && studySets.length === 0 && (
              <TableRow style={{ height: 59 * STUDY_SETS_PER_PAGE }}>
                <TableCell colSpan={4}>
                  <Typography color="textSecondary" align="center">
                    No results.
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {emptyRows > 0 && studySets.length > 0 && (
              <TableRow style={{ height: 59 * emptyRows }} data-testid={'StudySetListEmptyRow'}>
                <TableCell colSpan={4} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        {studySetsResStatus ? (
          <TablePagination
            component="div"
            colSpan={3}
            count={studySetsResStatus.total}
            rowsPerPage={STUDY_SETS_PER_PAGE}
            rowsPerPageOptions={[]}
            page={studySetsResStatus.offset / STUDY_SETS_PER_PAGE}
            onPageChange={(_, page) => handleChangePage(page)}
          />
        ) : (
          <span>Not loaded yet</span>
        )}
      </TableContainer>
    </>
  )
}

export default StudySetsTable
