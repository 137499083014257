import * as React from 'react'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import Login from '../Auth/Login/Login'
import BookSingle from '../Book/BookSingle/BookSingle'
import BooksTable from '../Book/BooksTable/BooksTable'
import CefrTool from '../CefrTool/CefrTool'
import Dashboard from '../Dashboard/Dashboard'
import ExercisesTable from '../Exercise/ExercisesTable/ExercisesTable'
import StudySetsTable from '../StudySet/StudySetsTable/StudySetsTable'
import StudySetSingle from '../StudySet/StudySetSingle/StudySetSingle'
import SchoolsTable from '../Schools/SchoolsTable'
import Settings from '../Settings/Settings'
import StudentsTable from '../Students/StudentsTable'
import TeachersTable from '../Teachers/TeachersTable'
import TutorsTable from '../Tutors/TutorsTable'
import AuthWall from './AuthWall/AuthWall'
import { ROUTES } from './routes'

const RouteStack: React.FC = () => {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <AuthWall authPage={<Login />}>
        <Dashboard>
          <Switch>
            <Route exact path={ROUTES.ROOT} component={BooksTable} />
            <Route
              path={ROUTES.BOOK}
              render={({ match: { url } }) => (
                <>
                  <Route exact path={`${url}`} component={() => <Redirect to={ROUTES.ROOT} />} />
                  <Route path={`${url}/:id/:exerciseType?/:exerciseId?`} component={BookSingle} />
                </>
              )}
            />
            <Route path={`${ROUTES.EXERCISE}/:exerciseType?/:exerciseId?`} component={ExercisesTable} />
            <Route path={ROUTES.SETTINGS} component={Settings} />
            <Route path={ROUTES.STUDENTS} component={StudentsTable} />
            <Route path={ROUTES.TEACHERS} component={TeachersTable} />
            <Route path={`${ROUTES.TUTORS}/:tutorId?`} component={TutorsTable} />
            <Route exact path={ROUTES.STUDY_SETS} component={StudySetsTable} />
            <Route path={`${ROUTES.STUDY_SETS}/:studySetId`} component={StudySetSingle} />
            <Route path={ROUTES.SCHOOLS} component={SchoolsTable} />
            <Route path={ROUTES.CERF_TOOL} component={CefrTool} />
            <Redirect to={ROUTES.ROOT} />
          </Switch>
        </Dashboard>
      </AuthWall>
    </Router>
  )
}

export default RouteStack
