import * as React from 'react'
import DashboardHeader from './DashboardHeader/DashboardHeader'
import styles from './Dashboard.module.scss'

interface Props {
  children: React.ReactElement
}

const Dashboard: React.FC<Props> = ({ children }) => {
  return (
    <>
      <DashboardHeader />
      <main className={styles.container}>{children}</main>
    </>
  )
}

export default Dashboard
