import { Dispatch } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { createSelector } from 'reselect'
import { ApiReqState, Paginated, PaginatedParams } from '../../../shared/api/types'
import { Logger } from '../../../shared/logger/Logger'
import { RootState } from '../../types'
import { selectBookSingleData, setSingleBookData } from '../Book/bookReducer'
import { BookModel, BookSingleReqState, BookSingleSetData } from '../Book/types'
import ExerciseApi from './exerciseApi'
import {
  ExerciseActions,
  ExerciseLoadCollection,
  ExerciseLoadCollectionReqState,
  ExerciseModel,
  ExerciseNotAssignedDelete,
  ExerciseNotAssignedSetNew,
  ExerciseNotAssignedUpdate,
  ExerciseOfBookDeleteSingle,
  ExerciseOfBookSetNew,
  ExerciseOfBookSetReqState,
  ExerciseOfBookSetResults,
  ExerciseOfBookUpdateSingle,
  ExerciseState,
  EXERCISE_ACTIONS
} from './types'

export const ExerciseInitialState: ExerciseState = {
  collection: {
    results: [],
    status: undefined,
    reqState: ApiReqState.IDLE
  },
  bookExercises: {
    bookId: null,
    results: [],
    reqState: ApiReqState.IDLE
  }
}

const exerciseReducer = (state: ExerciseState = ExerciseInitialState, action: ExerciseActions) => {
  switch (action.type) {
    case EXERCISE_ACTIONS.LOAD_COLLECTION:
      return {
        ...state,
        collection: {
          ...state.collection,
          ...action.collection
        }
      }
    case EXERCISE_ACTIONS.LOAD_COLLECTION_REQ_STATE:
      return {
        ...state,
        collection: {
          ...state.collection,
          reqState: action.reqState
        }
      }
    case EXERCISE_ACTIONS.NOT_ASSIGNED_UPDATE:
      return {
        ...state,
        collection: {
          ...state.collection,
          results: state.collection.results.map((exercise) =>
            exercise._id !== action.exerciseId ? exercise : action.data
          )
        }
      }
    case EXERCISE_ACTIONS.OF_BOOK_SET_RESULTS:
      return {
        ...state,
        bookExercises: {
          ...state.bookExercises,
          bookId: action.bookId,
          results: action.results
        }
      }
    case EXERCISE_ACTIONS.OF_BOOK_SET_REQ_STATE:
      return {
        ...state,
        bookExercises: {
          ...state.bookExercises,
          reqState: action.reqState
        }
      }
    case EXERCISE_ACTIONS.OF_BOOK_SET_NEW:
      if (state.bookExercises.bookId === action.bookId) {
        return {
          ...state,
          bookExercises: {
            ...state.bookExercises,
            bookId: action.bookId,
            results: [...state.bookExercises.results, action.data]
          }
        }
      } else {
        return state
      }

    case EXERCISE_ACTIONS.OF_BOOK_UPDATE_SINGLE:
      if (state.bookExercises.bookId === action.bookId) {
        return {
          ...state,
          bookExercises: {
            ...state.bookExercises,
            results: state.bookExercises.results.map((exercise) =>
              exercise._id === action.exerciseId ? action.data : exercise
            )
          }
        }
      } else {
        return state
      }
    case EXERCISE_ACTIONS.OF_BOOK_DELETE_SINGLE:
      if (state.bookExercises.bookId === action.bookId) {
        return {
          ...state,
          bookExercises: {
            ...state.bookExercises,
            results: state.bookExercises.results.filter((exercise) => exercise._id !== action.exerciseId)
          }
        }
      } else {
        return state
      }
    default:
      return state
  }
}

export default exerciseReducer

// ACTIONS
const setExerciseLoadCollectionReqState = (reqState: ApiReqState): ExerciseLoadCollectionReqState => ({
  type: EXERCISE_ACTIONS.LOAD_COLLECTION_REQ_STATE,
  reqState
})

const setExerciseLoadCollection = (collection: Paginated<ExerciseModel>): ExerciseLoadCollection => ({
  type: EXERCISE_ACTIONS.LOAD_COLLECTION,
  collection
})

export const getExercisesCollection = (params?: PaginatedParams) => async (
  dispatch: Dispatch<ExerciseLoadCollection | ExerciseLoadCollectionReqState>
) => {
  try {
    dispatch(setExerciseLoadCollectionReqState(ApiReqState.PENDING))

    const collection = (await ExerciseApi.load(params)).data
    dispatch(setExerciseLoadCollection(collection))

    dispatch(setExerciseLoadCollectionReqState(ApiReqState.RESOLVED))
  } catch (e) {
    dispatch(setExerciseLoadCollectionReqState(ApiReqState.REJECTED))
    Logger.log(e)
    throw new Error(e)
  }
}

export const createExercise = (
  data: FormData
): ThunkAction<void, RootState, unknown, ExerciseNotAssignedSetNew> => async (dispatch) => {
  try {
    const exercise = (await ExerciseApi.create(data)).data
    dispatch({ type: EXERCISE_ACTIONS.NOT_ASSIGNED_SET_NEW, data: exercise })
  } catch (e) {
    Logger.log(e)
    throw new Error(e)
  }
}

export const updateExercise = (
  data: FormData,
  exerciseId: ExerciseModel['_id']
): ThunkAction<void, RootState, unknown, ExerciseNotAssignedUpdate> => async (dispatch) => {
  try {
    const exercise = (await ExerciseApi.update(data, exerciseId)).data
    dispatch({ type: EXERCISE_ACTIONS.NOT_ASSIGNED_UPDATE, data: exercise, exerciseId })
  } catch (e) {
    Logger.log(e)
    throw new Error(e)
  }
}

export const deleteExercise = (
  exerciseId: ExerciseModel['_id']
): ThunkAction<void, RootState, unknown, ExerciseNotAssignedDelete> => async (dispatch) => {
  try {
    await ExerciseApi.delete(exerciseId)
    dispatch({ type: EXERCISE_ACTIONS.NOT_ASSIGNED_DELETE, exerciseId })
  } catch (e) {
    Logger.log(e)
    throw new Error(e)
  }
}

const setExerciseBookResults = (bookId: BookModel['_id'], results: ExerciseModel[]): ExerciseOfBookSetResults => ({
  type: EXERCISE_ACTIONS.OF_BOOK_SET_RESULTS,
  bookId,
  results
})

const setExerciseBookReqState = (reqState: ApiReqState): ExerciseOfBookSetReqState => ({
  type: EXERCISE_ACTIONS.OF_BOOK_SET_REQ_STATE,
  reqState
})

export const getExercisesForBook = (bookId: BookModel['_id']) => async (
  dispatch: Dispatch<ExerciseOfBookSetResults | ExerciseOfBookSetReqState>
) => {
  try {
    dispatch(setExerciseBookReqState(ApiReqState.PENDING))

    const results = (await ExerciseApi.loadBookExercises(bookId)).data

    dispatch(setExerciseBookResults(bookId, results))

    dispatch(setExerciseBookReqState(ApiReqState.RESOLVED))
  } catch (e) {
    dispatch(setExerciseBookReqState(ApiReqState.REJECTED))
    Logger.log(e)
    throw new Error(e)
  }
}

export const createExerciseForBook = (
  bookId: BookModel['_id'],
  data: FormData
): ThunkAction<void, RootState, unknown, BookSingleReqState | BookSingleSetData | ExerciseOfBookSetNew> => async (
  dispatch,
  getState
) => {
  try {
    const bookData = selectBookSingleData(getState(), bookId) as BookModel
    const exercise = (await ExerciseApi.createForBook(data, bookId)).data

    dispatch(setSingleBookData({ ...bookData, exercises: [...bookData.exercises, exercise._id] }))
    dispatch({ type: EXERCISE_ACTIONS.OF_BOOK_SET_NEW, bookId, data: exercise })
  } catch (e) {
    Logger.log(e)
    throw new Error(e)
  }
}

export const copyExerciseForBook = (
  bookId: BookModel['_id'],
  exerciseId: ExerciseModel['_id']
): ThunkAction<void, RootState, unknown, BookSingleReqState | BookSingleSetData | ExerciseOfBookSetNew> => async (
  dispatch,
  getState
) => {
  try {
    const bookData = selectBookSingleData(getState(), bookId) as BookModel
    const exercise = (await ExerciseApi.copyExercise(exerciseId)).data

    dispatch(setSingleBookData({ ...bookData, exercises: [...bookData.exercises, exercise._id] }))
    dispatch({ type: EXERCISE_ACTIONS.OF_BOOK_SET_NEW, bookId, data: exercise })
  } catch (e) {
    Logger.log(e)
    throw new Error(e)
  }
}

export const updateExerciseForBook = (
  bookId: BookModel['_id'],
  data: FormData,
  exerciseId: ExerciseModel['_id']
): ThunkAction<void, RootState, unknown, ExerciseOfBookUpdateSingle> => async (dispatch, getState) => {
  try {
    const exercise = (await ExerciseApi.update(data, exerciseId)).data
    dispatch({ type: EXERCISE_ACTIONS.OF_BOOK_UPDATE_SINGLE, bookId, data: exercise, exerciseId })
  } catch (e) {
    Logger.log(e)
    throw new Error(e)
  }
}

export const deleteExerciseForBook = (
  bookId: BookModel['_id'],
  exerciseId: ExerciseModel['_id']
): ThunkAction<void, RootState, unknown, ExerciseOfBookDeleteSingle> => async (dispatch, getState) => {
  try {
    await ExerciseApi.delete(exerciseId)
    dispatch({ type: EXERCISE_ACTIONS.OF_BOOK_DELETE_SINGLE, bookId, exerciseId })
  } catch (e) {
    Logger.log(e)
    throw new Error(e)
  }
}

// SELECTORS
export const selectExerciseCollection = (state: RootState) => state.exercise.collection
export const selectExercises = createSelector(selectExerciseCollection, (collection) => collection.results)
export const selectExercisesStatus = createSelector(selectExerciseCollection, (collection) => collection.status)
export const selectExercisesReqState = createSelector(selectExerciseCollection, (collection) => collection.reqState)

export const selectBookExercises = createSelector(
  (state: RootState) => state.exercise.bookExercises,
  (_: RootState, bookId: string) => bookId,
  (bookExercises, bookId) => {
    return bookExercises.bookId === bookId ? bookExercises.results : []
  }
)
