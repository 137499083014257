import {
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControlLabel,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography
} from '@material-ui/core'
import { ArrowDropDown } from '@material-ui/icons'
import * as React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import DashboardToolbar from '../../Dashboard/DashboardToolbar/DashboardToolbar'
import { ROUTES } from '../../routes/routes'
import { ApiFilterModel, ApiReqState, ApiSortModel } from '../../shared/api/types'
import SearchField from '../../shared/components/SearchField/SearchField'
import TableOverlay from '../../shared/components/TableOverlay/TableOverlay'
import CollectionQueryHelper from '../../shared/helpers/CollectionQueryHelper'
import {
  createExercise,
  deleteExercise,
  getExercisesCollection,
  selectExercises,
  selectExercisesReqState,
  selectExercisesStatus,
  updateExercise
} from '../../store/services/Exercise/exerciseReducer'
import { printExerciseContent } from '../../store/services/Exercise/helpers'
import {
  ExerciseModel,
  ExerciseReadWordRaceModel,
  ExerciseSentenceBuilderModel,
  ExerciseType,
  ExerciseWordBuilderModel,
  ExerciseWordPracticeModel
} from '../../store/services/Exercise/types'
import { RootState } from '../../store/types'
import ExerciseTypeSelector from '../components/ExerciseTypeSelector/ExerciseTypeSelector'
import SentenceBuilder from '../SentenceBuilder/SentenceBuilder'
import WordBuilder from '../WordBuilder/WordBuilder'
import WordRace from '../WordRace/WordRace'
import WordPractice from '../WordPractice/WordPractice'

interface State {
  sortParams: ApiSortModel
  filterParams: ApiFilterModel
  searchQuery: string
}

interface Props
  extends RouteComponentProps<{ exerciseType: ExerciseType; exerciseId: string }>,
    ConnectedProps<typeof connector> {}

export const EXERCISES_PER_PAGE = 10

class ExercisesTable extends React.Component<Props, State> {
  state: State = {
    sortParams: {},
    filterParams: {},
    searchQuery: ''
  }

  componentDidMount() {
    this.loadExercises()
  }

  loadExercises = async (page: number = 0) => {
    try {
      const { sortParams, filterParams, searchQuery } = this.state
      await this.props.getExercisesCollection({
        limit: EXERCISES_PER_PAGE,
        page,
        sort: sortParams,
        filter: filterParams,
        search: searchQuery
      })
    } catch (e) {}
  }

  handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    this.loadExercises(newPage)
  }

  handleSortRequest = (key: keyof ExerciseModel) => (e: React.MouseEvent<unknown>) => {
    this.setState(
      (state) => ({ sortParams: CollectionQueryHelper.setSortValue(key, state.sortParams) }),
      () => {
        this.loadExercises(0)
      }
    )
  }

  handleFilterRequest = (key: keyof ExerciseModel) => (e: React.ChangeEvent<any>) => {
    const value = e.target.value
    this.setState(
      (state) => ({ filterParams: CollectionQueryHelper.setFilterValue(key, value, state.filterParams, true) }),
      () => {
        this.loadExercises(0)
      }
    )
  }

  handleSearchQuery = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchQuery: e.target.value }, () => {
      this.loadExercises(0)
    })
  }

  render() {
    const { exercises, exercisesResStatus, exercisesReqState, history } = this.props
    const { exerciseType, exerciseId } = this.props.match.params

    const { sortParams, filterParams, searchQuery } = this.state

    const emptyRows = EXERCISES_PER_PAGE - exercises.length
    const isLoading = exercisesReqState === ApiReqState.IDLE || exercisesReqState === ApiReqState.PENDING

    const CURRENT_PAGE = (exercisesResStatus && exercisesResStatus.offset / EXERCISES_PER_PAGE) || 0

    const exerciseFormProps = (() => ({
      onSubmitSuccess: () => history.push(`${ROUTES.EXERCISE}`),
      onCancel: () => history.push(`${ROUTES.EXERCISE}`),
      createExercise: async (data: FormData) => {
        await this.props.createExercise(data)
        if (CURRENT_PAGE === 0) {
          this.loadExercises()
        }
      },
      updateExercise: async (data: FormData, exerciseId: string) => {
        await this.props.updateExercise(data, exerciseId)
      },
      deleteExercise: async (exerciseId: string) => {
        await this.props.deleteExercise(exerciseId)
        this.loadExercises()
      },
      bookChild: false
    }))()

    const exerciseData = exercises.find(({ _id }) => _id === exerciseId)

    return (
      <>
        <DashboardToolbar>
          <Grid container>
            <Grid item xs={6}>
              <ExerciseTypeSelector
                excluded={[ExerciseType.ReadSentence, ExerciseType.MultiChoiceQuestion]}
                onAdd={(exerciseType) => history.push(`${ROUTES.EXERCISE}/${exerciseType}/new`)}
              />
            </Grid>

            <Grid item xs={6} container justifyContent="flex-end">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={CollectionQueryHelper.isFilterValueActive('type', ExerciseType.WordBuilder, filterParams)}
                    onChange={this.handleFilterRequest('type')}
                    name="typeFilter"
                    color="primary"
                    value={ExerciseType.WordBuilder}
                  />
                }
                label="WORD CRAFT"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={CollectionQueryHelper.isFilterValueActive(
                      'type',
                      ExerciseType.SentenceBuilder,
                      filterParams
                    )}
                    onChange={this.handleFilterRequest('type')}
                    name="typeFilter"
                    color="primary"
                    value={ExerciseType.SentenceBuilder}
                  />
                }
                label="SENTENCE BUILDER"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={CollectionQueryHelper.isFilterValueActive('type', ExerciseType.ReadWordRace, filterParams)}
                    onChange={this.handleFilterRequest('type')}
                    name="typeFilter"
                    color="primary"
                    value={ExerciseType.ReadWordRace}
                  />
                }
                label="WORD RACE"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={CollectionQueryHelper.isFilterValueActive('type', ExerciseType.WordPractice, filterParams)}
                    onChange={this.handleFilterRequest('type')}
                    name="typeFilter"
                    color="primary"
                    value={ExerciseType.WordPractice}
                  />
                }
                label="PRO PRO"
              />
              <SearchField
                value={searchQuery}
                onChange={this.handleSearchQuery}
                onClear={() => this.handleSearchQuery({ target: { value: '' } } as React.ChangeEvent<HTMLInputElement>)}
              />
            </Grid>
          </Grid>
        </DashboardToolbar>
        <TableContainer component={Paper}>
          {!isLoading && exercises.length === 0 && (
            <TableOverlay>
              <Typography>Exercises not found.</Typography>
            </TableOverlay>
          )}
          {isLoading && (
            <TableOverlay data-testid="ExercisesTableLoader">
              <CircularProgress size={40} />
            </TableOverlay>
          )}
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell style={{ width: 420 }}>
                  <TableSortLabel
                    active={CollectionQueryHelper.isSortActive('type', sortParams)}
                    direction={CollectionQueryHelper.printSortDirection('type', sortParams)}
                    onClick={this.handleSortRequest('type')}
                    IconComponent={ArrowDropDown}>
                    Exercise type
                  </TableSortLabel>
                </TableCell>
                <TableCell>Content</TableCell>
                <TableCell>
                  <TableSortLabel
                    active={CollectionQueryHelper.isSortActive('difficultyLevel', sortParams)}
                    direction={CollectionQueryHelper.printSortDirection('difficultyLevel', sortParams)}
                    onClick={this.handleSortRequest('difficultyLevel')}
                    IconComponent={ArrowDropDown}>
                    Difficulty
                  </TableSortLabel>
                </TableCell>
                <TableCell>Countries</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {exercises.map((exercise) => (
                <TableRow
                  hover
                  key={exercise._id}
                  onClick={() => history.push(`${ROUTES.EXERCISE}/${exercise.type}/${exercise._id}`)}
                  data-testid={'ExercisesListRow'}>
                  <TableCell component="th" scope="row">
                    {exercise.type}
                  </TableCell>
                  <TableCell>{printExerciseContent(exercise)}</TableCell>
                  <TableCell>{exercise.difficultyLevel}</TableCell>
                  <TableCell>{exercise.targetCountries}</TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 59 * emptyRows }} data-testid={'ExercisesListEmptyRow'}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          {exercisesResStatus ? (
            <TablePagination
              component="div"
              colSpan={3}
              count={exercisesResStatus.total}
              rowsPerPage={EXERCISES_PER_PAGE}
              rowsPerPageOptions={[]}
              page={CURRENT_PAGE}
              onPageChange={this.handleChangePage}
            />
          ) : (
            <span>Not loaded yet</span>
          )}
        </TableContainer>
        <Dialog open={!!exerciseType && !!exerciseId}>
          <DialogContent style={{ minWidth: 600 }}>
            {exerciseType === ExerciseType.SentenceBuilder && (
              <SentenceBuilder {...exerciseFormProps} data={exerciseData as ExerciseSentenceBuilderModel} />
            )}
            {exerciseType === ExerciseType.WordBuilder && (
              <WordBuilder {...exerciseFormProps} data={exerciseData as ExerciseWordBuilderModel} />
            )}
            {exerciseType === ExerciseType.ReadWordRace && (
              <WordRace {...exerciseFormProps} data={exerciseData as ExerciseReadWordRaceModel} />
            )}
            {exerciseType === ExerciseType.WordPractice && (
              <WordPractice {...exerciseFormProps} data={exerciseData as ExerciseWordPracticeModel} />
            )}
          </DialogContent>
        </Dialog>
      </>
    )
  }
}

const connector = connect(
  (state: RootState) => ({
    exercises: selectExercises(state),
    exercisesResStatus: selectExercisesStatus(state),
    exercisesReqState: selectExercisesReqState(state)
  }),
  { getExercisesCollection, createExercise, updateExercise, deleteExercise }
)

export default connector(ExercisesTable)
