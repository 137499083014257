import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Slider } from '@material-ui/core'
import { Add as AddIcon, GetApp, Delete } from '@material-ui/icons'
import FileSaver from 'file-saver'
import * as React from 'react'
import { ChangeEvent } from 'react'
import ReactCrop, { Crop, PercentCrop, ReactCropProps } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { Logger } from '../../logger/Logger'
import styles from './ImageField.module.scss'
export interface CroppedResult {
  url: string
  blob: Blob
}

interface Props {
  name: string
  onRemove?: () => void
  onChange?: (e: ChangeEvent<{ name: string; value: CroppedResult }>) => void
  onOriginalChange?: (e: ChangeEvent<{ name: string; value: CroppedResult }>) => void
  value?: string
  originalValue?: string
  disabled?: boolean
  className?: string
  style?: React.CSSProperties
  label?: string
  aspectRatio?: number | null
  error?: boolean
  helperText?: string
  'data-testid'?: string
  onBlur?: () => void
  showAlert: (text: string) => void
  cropperProps?: Partial<ReactCropProps>
  width?: number | null
  height?: number | null
  skipCropping?: boolean
}

interface State {
  inputKey: string
  cropSrc: null | string
  crop: Crop
  croppedResult: CroppedResult | null
  customScale: number
  isFullScreen: boolean
  originalImage: CroppedResult | null
}

const checkIsMimeTypeJpeg = (mimeType?: string) => !!mimeType && /^image\/jpe?g$/i.test(mimeType)

class ImageField extends React.PureComponent<Props, State> {
  static defaultProps = {
    aspectRatio: 16 / 9,
    height: 100,
    width: 100
  }

  state: State = {
    inputKey: Math.random().toString(36),
    cropSrc: null,
    crop: {
      unit: '%',
      width: Number(this.props.width),
      height: Number(this.props.height),
      aspect: Number(this.props.aspectRatio)
    },
    croppedResult: null,
    customScale: 1,
    isFullScreen: false,
    originalImage: null
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.value !== this.props.value && (this.props.value === null || this.props.value === '')) {
      this.setState({ croppedResult: null })
    }
  }

  imageRef?: HTMLImageElement
  inputRef = React.createRef<HTMLInputElement>()

  onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]

      const isLt2M = file && file.size ? file.size / 1024 / 1024 < 2 : false

      if (!isLt2M) {
        if (this.props.showAlert) {
          this.setState({ inputKey: Math.random().toString(36) })
          this.props.showAlert('Max allowed image size is 2MB!')
        }

        return
      }

      const reader = new FileReader()
      reader.addEventListener('load', () => {
        if (this.props.skipCropping) {
          const original = {
            url: reader.result as string,
            blob: file
          }
          this.handleSave(original, original)
          return
        }

        this.setState({ cropSrc: reader.result as string })

        this.setState({
          originalImage: {
            url: reader.result as string,
            blob: file
          },
          crop: {
            unit: '%',
            width: Number(this.props.width),
            height: Number(this.props.height),
            aspect: Number(this.props.aspectRatio)
          }
        })
      })

      reader.readAsDataURL(e.target.files[0])
    }
  }

  onImageLoaded = (image: HTMLImageElement) => {
    this.imageRef = image
  }

  onCropComplete = (crop: Crop) => {
    this.makeClientCrop(crop)
  }

  onCropChange = (crop: Crop, percentCrop: PercentCrop) => {
    // handle issue with missing initial crop by checking for existing width or height
    if (percentCrop && (percentCrop.width || percentCrop.height)) {
      this.setState({
        crop: {
          ...percentCrop
        }
      })
    }
  }

  async makeClientCrop(crop: Crop) {
    if (this.imageRef && crop.width && crop.height) {
      const fileExtension = checkIsMimeTypeJpeg(this.state.originalImage?.blob.type) ? 'jpeg' : 'png'
      const { url, blob } = await this.getCroppedImg(this.imageRef, crop, `newFile.${fileExtension}`)
      this.setState({ croppedResult: { url, blob } })
    }
  }

  getCroppedImg(image: HTMLImageElement, crop: Crop, fileName: string): Promise<CroppedResult> {
    const canvas = document.createElement('canvas')
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    canvas.width = crop.width as number
    canvas.height = crop.height as number
    const ctx = canvas.getContext('2d')

    ctx!.drawImage(
      image,
      crop.x! * scaleX,
      crop.y! * scaleY,
      crop.width! * scaleX,
      crop.height! * scaleY,
      0,
      0,
      crop.width!,
      crop.height!
    )

    return new Promise((resolve, reject) => {
      const mimeType = checkIsMimeTypeJpeg(this.state.originalImage?.blob.type) ? 'image/jpeg' : 'image/png'
      canvas.toBlob((blob) => {
        if (!blob) {
          Logger.log('ImageField: Canvas is empty')
          reject(new Error('ImageField: Canvas is empty'))
          return
        }
        resolve({ url: window.URL.createObjectURL(blob), blob: new File([blob], fileName, { type: mimeType }) })
      }, mimeType)
    })
  }

  clearInputValue = () => {
    this.inputRef.current!.value = ''
  }

  handleZoom = (event: any, newValue: number | number[]) => {
    this.setState({ customScale: newValue as number })
  }

  handleSave = (cropped: CroppedResult | null, original: CroppedResult | null) => {
    this.props.onBlur && this.props.onBlur()

    if (this.props.onChange && cropped) {
      this.props.onChange({
        target: {
          name: this.props.name,
          value: cropped
        }
      } as React.ChangeEvent<{ name: string; value: CroppedResult }>)

      if (this.props.onOriginalChange && original) {
        this.props.onOriginalChange({
          target: {
            name: this.props.name + 'Original',
            value: original
          }
        } as React.ChangeEvent<{ name: string; value: CroppedResult }>)
      }
    }
    this.setState({ cropSrc: null })
    this.clearInputValue()
  }

  render() {
    const { crop, croppedResult, cropSrc, customScale, isFullScreen, originalImage } = this.state

    const {
      name,
      value,
      disabled,
      className,
      style,
      error,
      helperText,
      onBlur,
      cropperProps,
      skipCropping
    } = this.props
    const imageUrl = value || (croppedResult && croppedResult.url) || null
    return (
      <div>
        <IconButton
          disabled={!imageUrl}
          onClick={() => {
            if (imageUrl) FileSaver.saveAs(imageUrl)
          }}>
          <GetApp />
        </IconButton>
        {this.props.onRemove && imageUrl && (
          <IconButton onClick={this.props.onRemove}>
            <Delete />
          </IconButton>
        )}
        <div
          className={`${styles.ImageField} ${error ? styles.isError : ''} ${
            helperText ? styles.isHelperText : ''
          } ${className}`}
          style={style}
          data-testid={this.props['data-testid'] || 'ImageField'}
          aria-invalid={error ? 'true' : 'false'}>
          <div className={`${styles.inputWrapper}`}>
            {imageUrl && (
              <>
                <img
                  alt="Crop"
                  style={{ maxWidth: '100%', objectFit: 'cover' }}
                  src={imageUrl}
                  data-testid={`${this.props['data-testid'] || 'ImageField'}__preview`}
                />
              </>
            )}
            <div className={styles.labelWrapper}>
              <AddIcon />
            </div>
            {!disabled && (
              <input
                key={this.state.inputKey}
                ref={this.inputRef}
                name={name}
                type="file"
                accept="image/*"
                onChange={this.onSelectFile}
                data-testid={`${this.props['data-testid'] || 'ImageField'}__input`}
              />
            )}
          </div>
          {helperText && <span className={styles.helperText}>{helperText}</span>}
          {cropSrc && !skipCropping && (
            <Dialog open={true} fullScreen={isFullScreen}>
              <DialogTitle id="customized-dialog-title">
                Crop image
                <Slider
                  defaultValue={1}
                  max={1.5}
                  min={0.6}
                  value={customScale}
                  step={0.05}
                  onChange={this.handleZoom}
                />
              </DialogTitle>
              <DialogContent dividers>
                <div>
                  <ReactCrop
                    style={{ transform: `scale(${customScale})` }}
                    src={cropSrc}
                    crop={crop}
                    ruleOfThirds
                    onImageLoaded={this.onImageLoaded}
                    onComplete={this.onCropComplete}
                    onChange={this.onCropChange}
                    locked={true}
                    {...cropperProps}
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  onClick={() => {
                    this.clearInputValue()
                    this.setState({ croppedResult: null, cropSrc: null })
                    onBlur && onBlur()
                  }}>
                  Cancel
                </Button>
                {this.props.originalValue && (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      this.setState({ cropSrc: this.props.originalValue || null })
                    }}>
                    Reset
                  </Button>
                )}
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    this.setState((state) => ({ isFullScreen: !state.isFullScreen }))
                  }}>
                  Toggle fullscreen
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => this.handleSave(croppedResult, originalImage)}>
                  Save changes
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </div>
      </div>
    )
  }
}

export default ImageField
