import { CEFRLevels } from '@astrid/components/src/types'
import * as Yup from 'yup'
import { MixedSchema } from 'yup'

export const ValidationRules = {
  image: Yup.object().shape({
    url: Yup.string().required('Required'),
    blob: (Yup.mixed() as MixedSchema & { defined: (message: string) => MixedSchema }).defined('Image is required')
  }),
  video: Yup.object({
    originalUrl: Yup.string().optional(),
    _id: Yup.string().required('Video is required')
  }),
  required: Yup.string().required('Required'),
  requiredName: Yup.string()
    .required('Required')
    .matches(
      /^[a-zA-Z\u00c0-\u017eàáãạảăắằẳẵặâấầẩẫậèéẹẻẽêềếểễệđìíĩỉịòóõọỏôốồổỗộơớờởỡợùúũụủưứừửữựỳỵỷỹýÀÁÃẠẢĂẮẰẲẴẶÂẤẦẨẪẬÈÉẸẺẼÊỀẾỂỄỆĐÌÍĨỈỊÒÓÕỌỎÔỐỒỔỖỘƠỚỜỞỠỢÙÚŨỤỦƯỨỪỬỮỰỲỴỶỸÝ ,.'-]+$/,
      'Contains illegal characters'
    ),
  requiredNum: Yup.number().required('Required'),
  email: Yup.string().email('Email format is not valid.').required('Required'),
  difficultyLevel: Yup.number().min(0, 'Min difficulty level is 0').max(100, 'Max difficulty level is 100'),
  targetCountries: Yup.array().required('Required'),
  sentence: Yup.string().max(400, 'Maxiumum 400 characters').required('Required'),
  points: Yup.number().min(0, 'Minimum 0').required('Required'),
  cefrLevel: Yup.string().oneOf(Object.values(CEFRLevels)).required('Required'),
  optionalWithMaxLength: Yup.string().optional().max(400, 'Max 400 letters').nullable(),
  options: Yup.array()
    .of(Yup.string())
    .when(
      [
        'imageOption1',
        'imageOption2',
        'imageOption3',
        'imageOption4',
        'audioOptionUs1',
        'audioOptionUs2',
        'audioOptionUs3',
        'audioOptionUs4'
      ],
      {
        is: (
          imageOption1,
          imageOption2,
          imageOption3,
          imageOption4,
          audioOptionUs1,
          audioOptionUs2,
          audioOptionUs3,
          audioOptionUs4
        ) => {
          return (
            [imageOption1, imageOption2, imageOption3, imageOption4].filter((el) => el).length >= 2 ||
            [audioOptionUs1, audioOptionUs2, audioOptionUs3, audioOptionUs4].filter((el) => el).length >= 2
          )
        },
        then: Yup.array().of(Yup.string()).optional(),
        otherwise: Yup.array()
          .of(Yup.string().required('You must fill atleast 2 options'))
          .min(2, 'You must fill atleast 2 US options')
          .max(4)
      }
    )
}
