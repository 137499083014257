import { Dispatch } from 'redux'
import { ApiReqState } from '../../../shared/api/types'
import { Logger } from '../../../shared/logger/Logger'
import { RootState } from '../../types'
import ContentTagApi from './contentTagApi'
import {
  ContentTagActions,
  ContentTagDeleteData,
  ContentTagModel,
  ContentTagReqState,
  ContentTagSetData,
  ContentTagState,
  ContentType,
  CONTENT_TAG_ACTIONS
} from './types'

export const ContentTagInitialState: ContentTagState = {
  data: undefined,
  reqState: ApiReqState.IDLE
}

const contentTagReducer = (state: ContentTagState = ContentTagInitialState, action: ContentTagActions) => {
  switch (action.type) {
    case CONTENT_TAG_ACTIONS.SET_DATA:
      return {
        ...state,
        data: action.data
      }
    case CONTENT_TAG_ACTIONS.DELETE_DATA:
      return {
        ...state,
        data: undefined
      }
    case CONTENT_TAG_ACTIONS.REQ_STATE:
      return {
        ...state,
        reqState: action.reqState
      }
    default:
      return state
  }
}

export default contentTagReducer

// ACTIONS
const setContentTagData = (data: ContentTagModel): ContentTagSetData => ({
  type: CONTENT_TAG_ACTIONS.SET_DATA,
  data
})
const setContentTagReqState = (reqState: ApiReqState): ContentTagReqState => ({
  type: CONTENT_TAG_ACTIONS.REQ_STATE,
  reqState
})
const deleteContentTagData = (): ContentTagDeleteData => ({
  type: CONTENT_TAG_ACTIONS.DELETE_DATA
})

export const getContentTags = ({ contentId, contentType }: Omit<ContentTagModel, 'tags'>) => async (
  dispatch: Dispatch<ContentTagSetData | ContentTagReqState>
) => {
  try {
    dispatch(setContentTagReqState(ApiReqState.PENDING))

    const { data } = await ContentTagApi.load({ contentId, contentType })

    dispatch(setContentTagData(data))
    dispatch(setContentTagReqState(ApiReqState.RESOLVED))
  } catch (e) {
    dispatch(setContentTagReqState(ApiReqState.REJECTED))
    Logger.log(e)
  }
}

export const updateContentTags = ({ contentId, contentType, tags }: ContentTagModel) => async (
  dispatch: Dispatch<ContentTagSetData | ContentTagReqState>
) => {
  try {
    dispatch(setContentTagReqState(ApiReqState.PENDING))

    const { data } = await ContentTagApi.update({ contentId, contentType, tags })

    dispatch(setContentTagData(data))
    dispatch(setContentTagReqState(ApiReqState.RESOLVED))
  } catch (e) {
    dispatch(setContentTagReqState(ApiReqState.REJECTED))
    Logger.log(e)
  }
}

export const deleteContentTags = ({ contentId, contentType }: Omit<ContentTagModel, 'tags'>) => async (
  dispatch: Dispatch<ContentTagDeleteData | ContentTagReqState>
) => {
  try {
    dispatch(setContentTagReqState(ApiReqState.PENDING))

    await ContentTagApi.delete({ contentId, contentType })

    dispatch(deleteContentTagData())
    dispatch(setContentTagReqState(ApiReqState.RESOLVED))
  } catch (e) {
    dispatch(setContentTagReqState(ApiReqState.REJECTED))
    Logger.log(e)
  }
}

// SELECTORS
export const selectContentTags = (state: RootState, contentId: string, contentType: ContentType) =>
  state.contentTag.data?.contentId === contentId && state.contentTag.data?.contentType === contentType
    ? state.contentTag.data
    : undefined
export const selectContentTagsReqState = (state: RootState) => state.contentTag.reqState
