import { CEFRLevels } from '@astrid/components/src/types'
import { Checkbox, FormControlLabel, IconButton, Popover } from '@material-ui/core'
import { FilterList } from '@material-ui/icons'
import { bindPopover, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks'
import React from 'react'
import styles from './SelectCefrLevels.module.scss'

interface SelectCefrLevelsProps {
  selectedCefrLevels: CEFRLevels[]
  onSelectedCefrLevelsChange: (cefrLevel: CEFRLevels[]) => void
}

const SelectCefrLevels = ({
  selectedCefrLevels,
  onSelectedCefrLevelsChange: setSelectedCefrLevels
}: SelectCefrLevelsProps) => {
  const popupState = usePopupState({ variant: 'popper', popupId: 'SelectCefrLevelsPopper' })

  const handleChange = (cefrLevel: CEFRLevels) => {
    if (selectedCefrLevels.includes(cefrLevel)) {
      setSelectedCefrLevels(selectedCefrLevels.filter((selectedCefrLevel) => selectedCefrLevel !== cefrLevel))
    } else {
      setSelectedCefrLevels([...selectedCefrLevels, cefrLevel])
    }
  }

  return (
    <>
      <IconButton {...bindTrigger(popupState)}>
        <FilterList></FilterList>
      </IconButton>
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}>
        <div className={styles.popperContainer}>
          {Object.values(CEFRLevels).map((cefrLevel) => {
            return (
              <FormControlLabel
                key={cefrLevel}
                label={`${cefrLevel}`}
                control={
                  <Checkbox
                    onChange={() => handleChange(cefrLevel)}
                    checked={selectedCefrLevels.some(
                      (selectedCefrLevel) => selectedCefrLevel === cefrLevel
                    )}></Checkbox>
                }></FormControlLabel>
            )
          })}
        </div>
      </Popover>
    </>
  )
}

export default SelectCefrLevels
