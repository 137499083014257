import React from 'react'
import Chip from '@material-ui/core/Chip'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'

interface Props {
  options: string[]
  selected: string[]
  onChange: (selected: string[]) => void
  disabled?: boolean
}

const MultiSelector = ({ options, selected, disabled, onChange }: Props) => {
  return (
    <Autocomplete
      multiple
      freeSolo
      id="combobox"
      disabled={disabled}
      options={options}
      value={selected}
      onChange={(_, value) => onChange(value)}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => <Chip variant="outlined" label={option} {...getTagProps({ index })} />)
      }
      renderInput={(params) => <TextField {...params} variant="outlined" label="Topics" />}
    />
  )
}

export default MultiSelector
