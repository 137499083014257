import { AxiosPromise } from 'axios'
import API from '../../../shared/api/API'
import { ContentTagModel } from './types'

class ContentTagApi {
  static load = ({ contentType, contentId }: Omit<ContentTagModel, 'tags'>): AxiosPromise<ContentTagModel> =>
    API.get(`/content-tags/${contentType}/${contentId}`)

  static update = ({ contentType, contentId, tags }: ContentTagModel): AxiosPromise<ContentTagModel> =>
    API.put(`/content-tags/${contentType}/${contentId}`, { tags })

  static delete = ({ contentType, contentId }: Omit<ContentTagModel, 'tags'>): AxiosPromise<void> =>
    API.delete(`/content-tags/${contentType}/${contentId}`)
}

export default ContentTagApi
