import { ApiReqState } from '../../../shared/api/types'

export interface ContentTagState {
  data?: ContentTagModel
  reqState: ApiReqState
}

export enum CONTENT_TAG_TYPES {
  Topic = 'Topic',
  AdultOrKid = 'Adult or kid'
}

export enum CONTENT_TAG_AGE_OPTIONS {
  ADULT = 'Adult or kid:Adult',
  KID = 'Adult or kid:Kid'
}

export enum CONTENT_TAG_ACTIONS {
  REQ_STATE = 'CONTENT_TAG__REQ_STATE',
  SET_DATA = 'CONTENT_TAG__SET_DATA',
  DELETE_DATA = 'CONTENT_TAG__DELETE_DATA'
}

export enum ContentType {
  Book = 'book',
  LessonTemplate = 'lesson-template',
  PhonemePractice = 'phoneme-practice'
}

export interface ContentTagModel {
  contentType: ContentType
  contentId: string
  tags: string[]
}

export interface ContentTagReqState {
  type: CONTENT_TAG_ACTIONS.REQ_STATE
  reqState: ApiReqState
}

export interface ContentTagSetData {
  type: CONTENT_TAG_ACTIONS.SET_DATA
  data: ContentTagModel
}

export interface ContentTagDeleteData {
  type: CONTENT_TAG_ACTIONS.DELETE_DATA
}

export type ContentTagActions = ContentTagReqState | ContentTagSetData | ContentTagDeleteData
