import { combineReducers } from 'redux'
import { RootState } from './types'
import { AuthActions } from './services/Auth/types'
import authReducer, { AuthInitialState } from './services/Auth/authReducer'
import bookReducer, { BookInitialState } from './services/Book/bookReducer'
import exerciseReducer, { ExerciseInitialState } from './services/Exercise/exerciseReducer'
import { BookActions } from './services/Book/types'
import { ExerciseActions } from './services/Exercise/types'
import studentReducer, { StudentInitialState } from './services/Student/studentReducer'
import teacherReducer, { TeacherInitialState } from './services/Teacher/teacherReducer'
import tutorReducer, { TutorInitialState } from './services/Tutor/tutorReducer'
import { StudentActions } from './services/Student/types'
import { TeacherActions } from './services/Teacher/types'
import { TutorActions } from './services/Tutor/types'
import schoolReducer, { SchoolInitialState } from './services/School/schoolReducer'
import { SchoolActions } from './services/School/types'
import studySetReducer, { StudySetInitialState } from './services/StudySet/studySetReducer'
import { StudySetActions } from './services/StudySet/types'
import contentTagReducer, { ContentTagInitialState } from './services/ContentTag/contentTagReducer'
import { ContentTagActions } from './services/ContentTag/types'
import topicsReducer, { TopicsInitialState } from './services/Topics/topicsReducer'
import { TopicsActions } from './services/Topics/types'

export const combinedReducers = combineReducers({
  auth: authReducer,
  book: bookReducer,
  exercise: exerciseReducer,
  student: studentReducer,
  teacher: teacherReducer,
  tutor: tutorReducer,
  studySet: studySetReducer,
  school: schoolReducer,
  contentTag: contentTagReducer,
  topics: topicsReducer
})

export const initialRootState: RootState = {
  auth: AuthInitialState,
  book: BookInitialState,
  exercise: ExerciseInitialState,
  student: StudentInitialState,
  teacher: TeacherInitialState,
  tutor: TutorInitialState,
  studySet: StudySetInitialState,
  school: SchoolInitialState,
  contentTag: ContentTagInitialState,
  topics: TopicsInitialState
}

export const rootReducer = (
  state = initialRootState,
  action:
    | AuthActions
    | BookActions
    | ExerciseActions
    | StudentActions
    | TeacherActions
    | TutorActions
    | StudySetActions
    | SchoolActions
    | ContentTagActions
    | TopicsActions
) => {
  return combinedReducers(state, action)
}
