import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText } from '@material-ui/core'
import { Formik, FormikConfig, FormikHelpers, FormikProps, FormikValues } from 'formik'
import React, { useCallback, useContext, useState } from 'react'
import { AlertContext } from '../../shared/components/AlertContext/AlertContext'
import FormFooter from '../../shared/components/FormFooter/FormFooter'
import { jsonToFormData } from '../../shared/helpers/jsonToFormData'
import { ExerciseModel } from '../../store/services/Exercise/types'
import { ExerciseFormProps } from '../types'

interface FormFactoryProps<Values> extends ExerciseFormProps {
  onSubmit: (values: Values, helpers: FormikHelpers<Values>) => object
  exerciseId: ExerciseModel['_id'] | undefined
  submitDisabled?: (values: FormikProps<Values>) => boolean
  isCopyable?: boolean
  hideFooter?: boolean
}

export function ExerciseFormFactory<Values extends FormikValues = FormikValues, ExtraProps = {}>({
  children,
  formDisabled,
  onCancel,
  createExercise,
  updateExercise,
  deleteExercise,
  copyExercise,
  onSubmitSuccess,
  onSubmit,
  exerciseId,
  submitDisabled,
  isCopyable,
  hideFooter,
  ...rest
}: Omit<FormikConfig<Values>, 'onSubmit'> & ExtraProps & FormFactoryProps<Values>): JSX.Element {
  const { showAlert } = useContext(AlertContext)
  const isNew = exerciseId === undefined
  const handleSubmit = useCallback(
    async (values, { setSubmitting, ...helpers }) => {
      const preparedData = onSubmit(values, { setSubmitting, ...helpers })

      try {
        if (isNew) {
          await createExercise(jsonToFormData(preparedData))
        } else {
          await updateExercise(jsonToFormData(preparedData), exerciseId!)
        }
        onSubmitSuccess()
        showAlert('Exercise updated succesfully', 'success')
      } catch (e) {
        setSubmitting(false)
      }
    },
    // eslint-disable-next-line
    [isNew]
  )

  const handleDelete = useCallback(
    async (setSubmitting) => {
      if (deleteExercise) {
        setSubmitting(true)
        try {
          await deleteExercise(exerciseId!)
          onSubmitSuccess()
        } catch (e) {
          setSubmitting(false)
        }
      }
    },
    // eslint-disable-next-line
    [isNew]
  )

  const handleCopy = useCallback(
    async (setSubmitting) => {
      if (copyExercise) {
        setSubmitting(true)
        try {
          await copyExercise(exerciseId!)
          onSubmitSuccess()
        } catch (e) {
          setSubmitting(false)
        }
      }
    },
    // eslint-disable-next-line
    []
  )
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  return (
    <Formik enableReinitialize={true} onSubmit={handleSubmit} {...rest}>
      {(formikProps) => {
        const { dirty, isSubmitting, handleSubmit, setSubmitting } = formikProps
        return (
          <>
            {typeof children === 'function' ? children(formikProps) : children}
            {!hideFooter && (
              <FormFooter gutterBottom>
                {!isNew && deleteExercise && !formDisabled && (
                  <Button
                    style={{ marginRight: 'auto' }}
                    variant="outlined"
                    color="secondary"
                    disabled={isSubmitting}
                    onClick={() => setIsDeleteDialogOpen(true)}>
                    Delete
                  </Button>
                )}
                <Button variant="text" color="secondary" onClick={onCancel}>
                  Cancel
                </Button>

                {isCopyable && (
                  <Button
                    variant="contained"
                    color="primary"
                    data-testid="exerciseCopyBtn"
                    disabled={isNew}
                    onClick={() => handleCopy(setSubmitting)}>
                    {isSubmitting ? <CircularProgress size={24} color={'inherit'} /> : 'Copy'}
                  </Button>
                )}
                {!formDisabled && (
                  <Button
                    variant="contained"
                    color="primary"
                    data-testid="exerciseSubmitBtn"
                    disabled={submitDisabled ? submitDisabled(formikProps) : !dirty || isSubmitting}
                    onClick={() => handleSubmit()}>
                    {isSubmitting ? <CircularProgress size={24} color={'inherit'} /> : isNew ? 'Add' : 'Update'}
                  </Button>
                )}
              </FormFooter>
            )}

            <Dialog open={isDeleteDialogOpen}>
              <DialogContent style={{ minWidth: 600 }}>
                <DialogContentText>Are you sure you want to delete this page?</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setIsDeleteDialogOpen(false)} color="primary">
                  No
                </Button>
                <Button
                  onClick={(e) => {
                    handleDelete(setSubmitting)
                    setIsDeleteDialogOpen(false)
                    e.stopPropagation()
                  }}
                  color="primary"
                  autoFocus>
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )
      }}
    </Formik>
  )
}
