import * as React from 'react'
import { Button, Grid, MenuItem, TextField } from '@material-ui/core'
import { Formik } from 'formik'
import { ExerciseType } from '../../../store/services/Exercise/types'
import { useMemo } from 'react'

const exerciseTypeOptions = [
  { value: ExerciseType.ReadSentence, label: 'Reading page' },
  { value: ExerciseType.WordBuilder, label: 'Word Craft' },
  { value: ExerciseType.SentenceBuilder, label: 'Sentence builder' },
  { value: ExerciseType.MultiChoiceQuestion, label: 'Comprehension' },
  { value: ExerciseType.ReadWordRace, label: 'Word race' },
  { value: ExerciseType.WordPractice, label: 'Pronunciation Pro' }
]

interface Props {
  onAdd: (exerciseType: ExerciseType) => void
  disabled?: boolean
  excluded?: ExerciseType[]
}

const ExerciseTypeSelector: React.FC<Props> = ({ onAdd, disabled, excluded = [] }) => {
  const options = useMemo(() => exerciseTypeOptions.filter(({ value }) => !excluded?.includes(value)), [excluded])
  return (
    <div data-testid="ExerciseTypeSelector">
      <Formik
        initialValues={{ selectExerciseType: options[0].value }}
        onSubmit={({ selectExerciseType }) => onAdd(selectExerciseType)}>
        {({ values, handleChange, handleSubmit }) => {
          return (
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="selectExerciseType"
                  name="selectExerciseType"
                  size="small"
                  data-testid="selectExerciseType"
                  select
                  value={values.selectExerciseType}
                  onChange={handleChange('selectExerciseType')}
                  inputProps={{ 'data-testid': 'targetCountriesInput' }}
                  disabled={disabled}>
                  {options.map(({ value, label }) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={4}>
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  fullWidth
                  style={{ height: 40 }}
                  onClick={() => {
                    handleSubmit()
                  }}
                  data-testid="addNewExercise"
                  disabled={disabled}>
                  Add
                </Button>
              </Grid>
            </Grid>
          )
        }}
      </Formik>
    </div>
  )
}

export default ExerciseTypeSelector
