import React from 'react'
import styles from './CefrTool.module.scss'
import ClassificationTool from './ClassificationTool/ClassificationTool'

const CefrTool: React.FC = () => {
  return (
    <div className={styles.container}>
      <ClassificationTool></ClassificationTool>
    </div>
  )
}

export default CefrTool
