import { AstridLogo } from '@astrid/components'
import * as React from 'react'
import { NavLink } from 'react-router-dom'
import { ROUTES } from '../../routes/routes'
import NavButton from '../../shared/components/NavButton/NavButton'
import AuthApi from '../../store/services/Auth/authApi'
import styles from './DashboardHeader.module.scss'

const logout = () => {
  AuthApi.removeUserToken()
  window.location.reload()
}

const DashboardHeader: React.FC = () => {
  return (
    <header className={styles.root}>
      <div className={styles.container}>
        <NavLink to={ROUTES.ROOT} className={styles.logo}>
          <AstridLogo />
          ADMIN
        </NavLink>
        <nav className={styles.nav}>
          <div className={styles.navMain}>
            <NavButton
              exact
              to={ROUTES.ROOT}
              isActive={(props) => !!props || window.location.pathname.includes(ROUTES.BOOK)}>
              Books
            </NavButton>
            <NavButton to={ROUTES.EXERCISE}>Exercises</NavButton>
            <NavButton to={ROUTES.STUDENTS}>Students</NavButton>
            <NavButton to={ROUTES.TEACHERS}>Teachers</NavButton>
            <NavButton to={ROUTES.SCHOOLS}>Schools</NavButton>
            <NavButton to={ROUTES.TUTORS}>Tutors</NavButton>
            <NavButton to={ROUTES.STUDY_SETS}>Study Sets</NavButton>
            <NavButton to={ROUTES.CERF_TOOL}>CEFR Tool</NavButton>
          </div>
          <div className={styles.navRight}>
            <NavButton to={ROUTES.SETTINGS}>Admin settings</NavButton>
            <NavButton to={'#'} withoutActiveStatus onClick={logout}>
              Log out
            </NavButton>
          </div>
        </nav>
      </div>
    </header>
  )
}

export default DashboardHeader
