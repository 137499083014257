import { Button, IconButton, Radio, TextField, Typography } from '@material-ui/core'
import { Clear, GetApp } from '@material-ui/icons'
import FileSaver from 'file-saver'
import { FormikErrors, FormikTouched } from 'formik'
import React, { useState } from 'react'
import styles from '../Comprehension.module.scss'
import { ComprehensionFormFields } from '../types'

interface ImageFormProps {
  handleTranslationClick: (text: string, translateTo: 'uk' | 'us') => Promise<string | undefined>
  handleChange: Function
  setFieldTouched: Function
  setFieldValue: Function
  values: ComprehensionFormFields
  touched: FormikTouched<ComprehensionFormFields>
  formDisabled: boolean
  errors: FormikErrors<ComprehensionFormFields>
  isVideoOverlay?: boolean
}

interface ImageInputProps {
  file: File | string | null | undefined
  uniqueName: string
  className?: string
  text: string
  style?: React.CSSProperties
  removeImage: () => void
  setImage: (image: File) => void
}

const SINGLE_IMAGE_SIZE_LIMIT = 2

const ImageInput: React.FC<ImageInputProps> = ({ file, uniqueName, setImage, text, removeImage, style, className }) => {
  const [error, setError] = useState<'size' | 'format' | false>(false)

  const checkImageAndUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i
    const image = e.target.files?.item(0)
    const fileName = e.target.value
    if (image) {
      if (image.size > SINGLE_IMAGE_SIZE_LIMIT * 1024 * 1024) {
        setError('size')
        return
      }
      if (!allowedExtensions.exec(fileName)) {
        setError('format')
        return
      }
      setError(false)
      setImage(image)
    }
  }

  return file ? (
    <div className={`${styles.imageDisplay} ${className ? className : ''}`} style={style}>
      <div>
        <img src={typeof file === 'string' ? file : URL.createObjectURL(file)} alt="Question" />
        <div className={styles.imageDisplayOverlay} onClick={removeImage}>
          <Clear />
        </div>
      </div>
    </div>
  ) : (
    <div className={`${styles.imageUpload} ${className ? className : ''}`} style={style}>
      <input
        type="file"
        name={uniqueName}
        id={uniqueName}
        accept=".jpg,.jpeg,.png,.gif"
        onChange={checkImageAndUpload}
      />
      <label htmlFor={uniqueName}>{text}</label>
      {!!error && (
        <span>
          {error === 'size'
            ? `File too large. Limit is ${SINGLE_IMAGE_SIZE_LIMIT}MiB.`
            : 'Please use a jpg, gif, or png image for your avatar.'}
        </span>
      )}
    </div>
  )
}

export const ImageQuestionForm: React.FC<ImageFormProps> = ({
  handleChange,
  values,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
  formDisabled,
  handleTranslationClick
}) => {
  return (
    <>
      <Typography variant="h6">UK</Typography>

      <TextField
        variant="outlined"
        margin="normal"
        fullWidth
        id="question"
        label="Question"
        placeholder="Enter the question"
        name="question"
        size="small"
        onChange={handleChange('question')}
        value={values.question}
        error={touched.question && !!errors.question}
        helperText={(touched.question && errors.question) || ''}
        onBlur={() => setFieldTouched('question')}
        disabled={formDisabled}
      />

      <Button
        onClick={async () => {
          setFieldValue('questionUs', await handleTranslationClick(values.question || '', 'us'))
        }}
        variant="outlined"
        color="primary"
        className={styles.translateButton}>
        Translate to US
      </Button>

      <Typography variant="h6">US</Typography>

      <TextField
        variant="outlined"
        margin="normal"
        fullWidth
        id="questionUs"
        label="Question"
        placeholder="Enter the question"
        name="questionUs"
        size="small"
        onChange={handleChange('questionUs')}
        value={values.questionUs}
        error={touched.questionUs && !!errors.questionUs}
        helperText={(touched.questionUs && errors.questionUs) || ''}
        onBlur={() => setFieldTouched('questionUs')}
        disabled={formDisabled}
      />

      <Button
        onClick={async () => {
          setFieldValue('question', await handleTranslationClick(values.question || '', 'uk'))
        }}
        variant="outlined"
        color="primary"
        className={styles.translateButton}>
        Translate to UK
      </Button>

      <ImageInput
        file={values.imageQuestion}
        uniqueName="imageQuestion"
        text="+ Upload Question Image"
        setImage={(img) => setFieldValue('imageQuestion', img)}
        removeImage={() => setFieldValue('imageQuestion', null)}
      />
      <IconButton
        disabled={!values.imageQuestion}
        onClick={() => {
          if (values.imageQuestion) FileSaver.saveAs(values.imageQuestion, 'imageQuestion.png')
        }}>
        <GetApp />
      </IconButton>
    </>
  )
}

export const ImageAnswerForm: React.FC<ImageFormProps> = ({
  values,
  setFieldValue,
  formDisabled,
  handleChange,
  isVideoOverlay
}) => {
  const options = isVideoOverlay
    ? [values.imageOption1, values.imageOption2]
    : [values.imageOption1, values.imageOption2, values.imageOption3, values.imageOption4]
  return (
    <>
      {options.map((option, index) => (
        <div key={`${option}${index}`} className={styles.imageOption}>
          <Radio
            checked={values.answer === index}
            onChange={() => setFieldValue('answer', index)}
            value={index}
            name="option-answer"
            color="primary"
            style={{ marginRight: 8 }}
            inputProps={{ 'data-testid': `option-${index}-answer` } as React.InputHTMLAttributes<HTMLInputElement>}
            disabled={formDisabled || option === undefined || option === null}
          />
          <div>
            <ImageInput
              file={option}
              uniqueName={`imageOption${index + 1}`}
              text="+ Upload Answer Image"
              setImage={(img) => setFieldValue(`imageOption${index + 1}`, img)}
              removeImage={() => {
                setFieldValue(`imageOption${index + 1}`, null)
                if (values.answer === index) {
                  const switchAnswerTo = options.findIndex((opt, i) => !!opt && i !== index)
                  setFieldValue('answer', switchAnswerTo !== -1 ? switchAnswerTo : 0)
                }
              }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              multiline
              minRows={3}
              id={`imageOption${index + 1}Attribute`}
              label={`Image Option ${index + 1} Attribution`}
              name={`imageOption${index + 1}Attribute`}
              size="small"
              onChange={handleChange(`imageOption${index + 1}Attribute`)}
              value={values[`imageOption${index + 1}Attribute` as keyof ComprehensionFormFields]}
              helperText="How to add a link: [Hyperlink text](http://www.example.com)"
              disabled={formDisabled}
            />
          </div>
          <IconButton
            disabled={!option}
            onClick={() => {
              if (option) FileSaver.saveAs(option, `imageOption${index + 1}.png`)
            }}>
            <GetApp />
          </IconButton>
        </div>
      ))}
    </>
  )
}
