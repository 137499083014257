import * as React from 'react'
import { useCallback, useContext, useState } from 'react'
import { Snackbar } from '@material-ui/core'
import { Alert, Color } from '@material-ui/lab'

interface AlertState {
  opened: boolean
  type: Color
  text: string
}

interface AlertContext extends AlertState {
  showAlert: (text: string, type?: Color) => void
  close: () => void
}

export const AlertContext = React.createContext<AlertContext>({
  opened: false,
  type: 'error',
  text: '',
  showAlert: () => {},
  close: () => {}
})

const AlertContextProvider: React.FC = ({ children }) => {
  const [alertState, setAlertState] = useState<AlertState>({ opened: false, type: 'info', text: '' })

  const show = useCallback((text: string, type: Color = 'error') => {
    setAlertState({ opened: true, type, text })
  }, [])

  const close = useCallback(() => {
    setAlertState((state) => ({ ...state, opened: false }))
    setTimeout(() => {
      setAlertState((state) => ({ ...state, text: '' }))
    }, 500)
  }, [])

  return (
    <AlertContext.Provider
      value={{
        ...alertState,
        showAlert: show,
        close
      }}>
      {children}
    </AlertContext.Provider>
  )
}

const AlertContextConsumer: React.FC = () => {
  const { opened, text, type, close } = useContext(AlertContext)
  return (
    <Snackbar open={opened} autoHideDuration={4000} onClose={close}>
      <Alert variant="filled" severity={type}>
        {text}
      </Alert>
    </Snackbar>
  )
}

export const AlertProvider: React.FC = ({ children }) => (
  <AlertContextProvider>
    {children}
    <AlertContextConsumer />
  </AlertContextProvider>
)
